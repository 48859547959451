import React from 'react';
import './Styles/A-Portfolio.scss';
import './Styles/A-PortfolioResponsive.scss';

import { useNavigate } from 'react-router-dom';
import PortfolioCarousel from './Components/PortfolioCarousel/PortfolioCarousel';

const Portfolio = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate('/gallery');
  };

  return (
    <section className="portfolioSection">
      <div className="sectionHeader">
        <img src="/img/philo.gif" alt="Bulle avec le texte blah blah blah" className="iconeSectionHeader"/>
        <h2>NOS PROJETS</h2>
        <h3>Plongez dans notre univers créatif.</h3>
        <p className='blocInfoSection'>
  Chez <strong>PAÏTAWEB</strong>, chaque projet est une <strong>aventure unique</strong> qui allie <strong>créativité</strong>, <strong>expertise</strong> et <strong>innovation</strong>. Explorez notre portfolio pour découvrir comment nous transformons des idées en <strong>solutions numériques performantes</strong>. Que ce soit pour la <strong>conception de sites web</strong>, le <strong>développement d’applications mobiles</strong> ou l’<strong>optimisation SEO</strong>, nos réalisations témoignent de notre engagement à fournir des résultats exceptionnels. Plongez dans notre <strong>univers créatif</strong> et laissez-vous inspirer par nos réussites.
</p>      </div>
      <div className="portfolioBlock">
        <PortfolioCarousel />
      </div>

      <button onClick={handleClick} className="button-details">
        Voir Tous Nos Projets
      </button>
    </section>
  );
};

export default Portfolio;