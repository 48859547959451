import React, { useState } from 'react';
import './Styles/A-Parcours.scss';
import './Styles/A-ParcoursResponsive.scss';
import { Link } from 'react-router-dom';
import ParcoursCarrousel from './Components/ParcoursCarrousel/ParcoursCarrousel';
import '../../App.scss';

const Parcours = () => {
  const [imageSrc, setImageSrc] = useState('/img/ampoule.webp');

  const handleMouseEnter = () => {
    setImageSrc('/img/ampoulejaune.webp');
  };

  const handleMouseLeave = () => {
    setImageSrc('/img/ampoule.webp');
  };

  return (
    <section className="parcoursSection">
      <div className="sectionHeader">
        <img
          src={imageSrc}
          alt="Ampoule"
          className="iconeSectionHeader"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
        <h2>LES ÉTAPES</h2>
        <h3>Pour vous accompagner de l'idée au clic</h3>
        <p className="blocInfoSection">
          Chez PAÏTAWEB, nous transformons vos visions digitales en <strong>réalités tangibles</strong>. Notre processus vous accompagne à chaque étape, de la <strong>conceptualisation</strong> jusqu'au <strong>lancement</strong> de votre projet. Nous débutons par une phase de <strong>stratégie</strong> pour cerner vos objectifs, suivie par la création d'un design <strong>UI/UX captivant</strong>. Le <strong>développement</strong>, réalisé en mode <strong>agile</strong> pour garantir <strong>flexibilité</strong> et <strong>efficacité</strong>, et pour conclure des tests <strong>rigoureux</strong> assurant une <strong>performance optimale</strong>.
        </p>
      </div>
      <div className="ParcoursBlock">
        <ParcoursCarrousel />
      </div>
    </section>
  );
};

export default Parcours;