import React from 'react';
import './ContactForm.scss';  
import './ContactFormMobile.scss';  
import './ContactFormTablette.scss';  

const ContactForm = () => {
  return (
    <div className="contactFormContainer">
      <form className="contactForm">
        <div className="formUpperRow">
          <div className='blocBoutonContact'>
            <h3>Démarrons l'aventure !</h3>
            
            
          </div>
          <div className="formFields">
            <div className="formGroup">
              <input type="text" id="name" name="name" required placeholder="Nom ou société" />
            </div>
            <div className="formGroup">
              <input type="email" id="email" name="email" required placeholder="Email ou Numéro de téléphone" />
            </div>
            <div className="formGroup">
              <input type="text" id="subject" name="subject" required placeholder="Objet de votre demande" />
            </div>
          </div>
        </div>
        <div className="formGroup messageGroup">
          <textarea id="message" name="message" required placeholder="Votre message"></textarea>
        </div>
        <button type="submit" className="submitBtn">Envoyer</button>
      </form>
    </div>
  );
};

export default ContactForm;