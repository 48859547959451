import React, { useState, useEffect, useRef } from 'react';
import './ParcoursCarrousel.scss';
import './ParcoursCarrouselMobile.scss';
import './ParcoursCarrouselTablette.scss';

import stepsData from '../../../../Data/StepsData';

const ParcoursCarrousel = () => {
  const [steps, setSteps] = useState(stepsData);
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const autoScrollInterval = useRef(null);

  useEffect(() => {
    startAutoScroll();
    return () => {
      stopAutoScroll();
    };
  }, []);

  const startAutoScroll = () => {
    stopAutoScroll();
    autoScrollInterval.current = setInterval(() => {
      handleNextStep();
    }, 5000); // Durée en ms, ajustez selon votre besoin
  };

  const stopAutoScroll = () => {
    if (autoScrollInterval.current) {
      clearInterval(autoScrollInterval.current);
    }
  };

  const handleNextStep = () => {
    const nextIndex = (activeStepIndex + 1) % steps.length;
    setActiveStepIndex(nextIndex);
    rearrangeSteps();
  };

  const rearrangeSteps = () => {
    setSteps(prevSteps => {
      const newSteps = [...prevSteps];
      const currentStep = newSteps.shift();
      newSteps.push(currentStep);
      return newSteps;
    });
  };

  return (
    <div className="parcoursCarrousel">
      <div className="carouselNavigation">
        {/* Elements de navigation si nécessaire */}
      </div>
      <div className="carouselCards">
        {steps.map((step, index) => (
          <div
            key={step.number}
            className={`carouselCard ${index === activeStepIndex ? 'active' : ''}`}
            onClick={() => { stopAutoScroll(); handleNextStep(); }}
            onMouseEnter={stopAutoScroll}
            onMouseLeave={startAutoScroll}
          >
            <div className='contenuCard'>
              <div className='colonne1'>
                <div className='ligneUn'>
                  <h5 className="stepNumber">{step.number}</h5>
                  <h4 className="stepTitle">{step.title}</h4>
                </div>
                <p className="stepDescription">{step.description}</p>
              </div>
              <div className='stepLogo'>
                <img src={step.logo} alt={`Logo de l'étape ${step.title}`} />
              </div>
            </div>
            <div className="stepKeyPoints" style={{ width: '100%' }}>
              {step.tags.map((keyStep) => (
                <div key={keyStep} className="keyStep">{keyStep}</div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ParcoursCarrousel;