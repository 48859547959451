import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import './DetailedServicesComponent.scss';
import './DetailedServicesComponentTablette.scss';
import './DetailedServicesComponentMobile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DetailedServicesComponent = ({ title, description, tag, icons, color, path, image, descriptionMobile }) => {
  let navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const goToServicePage = (event) => {
    event.stopPropagation(); // Empêche l'événement de propagation
    navigate(`/services/${path}`);
  };

  const props = useSpring({
    transform: isHovered ? 'scale(1.05)' : 'scale(1)',
    boxShadow: isHovered
      ? '0px 10px 20px rgba(0, 0, 0, 0.3)'
      : '0px 5px 15px rgba(0, 0, 0, 0.1)',
    config: { tension: 300, friction: 10 }
  });

  return (
    <div
      className="service-card cartedeservices"
      onClick={goToServicePage}
    >
      <animated.div
        className="service-header"
        style={{ ...props, backgroundColor: color, cursor: 'pointer' }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className='colonneImage'>
          <img src={image} alt={title} />
        </div>
        <div className='colonneContenu'>
          <div className='ligne1'>
            <h4>{title}</h4>
            <p className={`tag ${tag}`}>{tag}</p>
          </div>
          <div className='ligne2'>
            <p>{descriptionMobile}</p>
          </div>
          <div className='ligne3'>
            <button className='btnInfosProjet' onClick={goToServicePage}>En savoir plus</button>
            <div className='icons'>
              {icons.map((icon, index) => (
                <FontAwesomeIcon key={index} icon={icon} />
              ))}
            </div>
          </div>
        </div>
      </animated.div>
    </div>
  );
};

export default DetailedServicesComponent;