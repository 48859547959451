// PortfolioData.js
const PortfolioData = [
  {
    id: 1, 
    number: '01',
    title: 'Le Cro-Magnon',
    description: 'Création d’un site vitrine avec REACT et de la charte graphique adaptée à l’ambiance du restaurant',
    image: './img/logocro.png', //IMAGE DE FOND
    path: 'le-cro-magnon', // Le chemin vers la page de détail
    contentShort: 'Le Cro-Magnon est un restaurant situé à Orcières, offrant une expérience culinaire unique avec une ambiance conviviale et chaleureuse.',
    content: 'Le Cro-Magnon est un projet de site vitrine réalisé avec REACT et SCSS. Le design original a été adapté pour refléter l’ambiance chaleureuse et conviviale du restaurant. Le site comprend plusieurs sections : un menu interactif, une galerie photo, et une section contact avec intégration de l’API Google Maps et un bouton pour appeler directement le restaurant. Le tout est optimisé pour une navigation fluide et une expérience utilisateur agréable.',
    tag: 'Création site web',
    logo: '../img/logocro.png',
    languages: ['HTML', 'CSS', 'JS', 'REACT'],
    url: 'http://lecromagnon-orcieres.fr/',
    photos: ['../img/Projets/Cro/1.png','../img/Projets/Cro/2.png','../img/Projets/Cro/3.png','../img/Projets/Cro/4.png','../img/Projets/Cro/5.png'], // Chemins des photos
    keyPhrases: ['Développement avec REACT', 'Design SCSS personnalisé', 'API Google Maps intégrée', 'Fonctionnalités interactives'], // Phrases clés
    gradient: 'linear-gradient(to right, #12c2e9, #c471ed, #f64f59);', // Dégradé souhaité
  },
 
  {
    id: 2,
    number: '02',
    title: "L'atelier du Déoule",
    description: 'Création d’une landing page avec WordPress et un thème personnalisé',
    image: './img/blancdeoule.png', //IMAGE DE FOND
    path: 'deoule', // Le chemin vers la page de détail
    contentShort: "L'atelier du Déoule est un site vitrine offrant toutes les informations nécessaires sur les services, la localisation et les moyens de contact.",
    content: "L'atelier du Déoule est un projet de landing page réalisé avec WordPress. Répondant aux besoins spécifiques du client, le site offre une facilité de contact, une localisation précise, et une présentation détaillée des services. Le thème a été entièrement personnalisé et adapté à l'image de l'atelier. Le site est 100% responsive, garantissant une expérience utilisateur optimale sur tous les appareils. Des fonctionnalités clés incluent l'intégration d'une carte de localisation, des formulaires de contact simplifiés, et une présentation visuelle attractive des services.",
    tag: 'WordPress',
    logo: '../img/deoule.svg',
    languages: ['HTML', 'CSS', 'JS', 'WordPress'],
    url: 'https://www.atelierdudeoule.fr/',
    photos: ['../img/Projets/Deoule/1.png','../img/Projets/Deoule/2.png','../img/Projets/Deoule/3.png','../img/Projets/Deoule/4.png','../img/Projets/Deoule/5.png','../img/Projets/Deoule/6.png','../img/Projets/Deoule/7.png','../img/Projets/Deoule/8.png',], // Chemins des photos
    keyPhrases: ['Thème WordPress personnalisé', 'Landing page optimisée', 'Site 100% responsive', 'Intégration API Google Maps'], // Phrases clés
    gradient: 'linear-gradient(to right, #2C5364, #203A43, #0F2027)', // Dégradé souhaité
  },

  {
    id: 3,
    number: '03',
    title: "PAÏTAWEB",
    description: 'Création d’un site vitrine avec REACT et une charte graphique personnalisée',
    image: './img/logoblanc.png', // IMAGE DE FOND
    path: 'paitaweb', // Le chemin vers la page de détail
    contentShort: 'PAÏTAWEB est un site vitrine complet présentant mon entreprise de développement web, avec une interface utilisateur et une expérience utilisateur optimisées et personnalisées.',
    content: "PAÏTAWEB est un projet de site vitrine réalisé avec REACT et SCSS, présentant mon entreprise de développement web. Le site est entièrement responsive et propose un contenu personnalisé, des animations, et une UI/UX adaptées. L'utilisation de fichiers de données pour la programmation d'objets permet une gestion efficace des composants. Tous les composants du site ont été créés sur mesure pour offrir une expérience utilisateur fluide et attrayante.",
    tag: 'Création site web',
    logo: '../img/logo.png',
    languages: ['HTML', 'CSS', 'JS', 'REACT'],
    url: 'http://paitaweb.fr',
    photos: ['../img/Projets/PAITAWEB/1.png','../img/Projets/PAITAWEB/2.png','../img/Projets/PAITAWEB/3.png','../img/Projets/PAITAWEB/4.png','../img/Projets/PAITAWEB/5.png','../img/Projets/PAITAWEB/6.png','../img/Projets/PAITAWEB/7.png','../img/Projets/PAITAWEB/8.png','../img/Projets/PAITAWEB/9.png','../img/Projets/PAITAWEB/10.png',], // Chemins des photos
    keyPhrases: ['Développement avec REACT', 'Design SCSS personnalisé', 'Site 100% responsive', 'Animations et UI/UX adaptées'], // Phrases clés
    gradient: 'linear-gradient(to right, #2C5364, #203A43, #0F2027)', // Dégradé souhaité
  },



    

    
  

    
  ];
  
  export default PortfolioData;
  