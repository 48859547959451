import React from 'react';
import './Styles/Contact.scss';
import './Styles/ContactTablette.scss';
import './Styles/ContactMobile.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

import ContactForm from './Components/ContactForm/ContactForm';

const Contact = () => {
  return (
    <section className="contactSection">
      <div className="sectionHeader">
        <img src="/img/blabla.gif" alt="Bulle avec le texte blah blah blah" className="iconeSectionHeader"/>
        <h2>CONTACT</h2>
        <h3>Connectons nos Idées pour Créer le futur</h3>
        <p className='blocInfoSection'>
  Vous avez un projet en tête ou des questions sur nos services ? Chez <strong>PAÏTAWEB</strong>, nous sommes toujours à l’écoute de vos <strong>idées</strong> et de vos <strong>besoins</strong>. Que vous souhaitiez discuter d’une <strong>collaboration future</strong>, obtenir un <strong>devis</strong> ou simplement en savoir plus sur nos <strong>solutions numériques</strong>, n’hésitez pas à nous <strong>contacter</strong>. Ensemble, nous pouvons créer le <strong>futur de votre présence en ligne</strong>. Connectons nos idées et faisons avancer votre <strong>projet</strong> dès aujourd’hui !
</p>        <div className='buttonContactSection'>
          <a href="mailto:mateo@paita.net" className="contactButton">
            <span className="defaultContent">
              <FontAwesomeIcon icon={faEnvelope} /> Par Mail
            </span>
            <span className="hoverContent">mateo@paita.net</span>
          </a>
          <a href="tel:+33608617000" className="contactButton">
            <span className="defaultContent">
              <FontAwesomeIcon icon={faPhone} /> Par Téléphone
            </span>
            <span className="hoverContent">06 08 61 70 00</span>
          </a>
        </div>
      </div>
      <div className="contactBlock">
        <ContactForm />
      </div>

    </section>
  );
};

export default Contact;