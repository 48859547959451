import React, { useEffect } from 'react';
import Typed from 'typed.js';
import './TexteAnimated.scss'; // Assurez-vous que le fichier CSS existe

const TexteAnimated = ({ textOptions }) => {
  useEffect(() => {
    // Utilisation des textOptions passés en prop ou des valeurs par défaut
    const options = {
      strings: textOptions || [
        "site web.",
        "boutique en ligne",
        "refonte de site web.",
        "rêveur infatigable."
      ],
      typeSpeed: 80,
      backSpeed: 50,
      backDelay: 2000,
      startDelay: 300,
      loop: true,
      showCursor: true,
      cursorChar: '|'
    };

    // Initialisation de Typed.js sur l'élément avec la classe .text-typed
    const typed = new Typed('.text-typed', options);

    return () => {
      typed.destroy(); // Nettoie l'instance Typed lors du démontage du composant
    };
  }, [textOptions]); // Dépendance à textOptions pour recharger si elles changent

  return (
    <h1 className="text-typed-container">
      <span className='lightText'>Pour vos projets de </span>
      <span className="text-typed"></span> {/* Changement du nom de classe pour éviter les conflits */}
    </h1>
  );
};

export default TexteAnimated;
