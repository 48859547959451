// ServicesData.js

import { faJsSquare, faWordpress, faHtml5, faApple, faReact, faSass, faFigma, faSketch, faPaypal, faApplePay,faAndroid, faAppStoreIos, faGoogle,faGooglePlay } from '@fortawesome/free-brands-svg-icons';
import { faKeyboard, faMagnifyingGlass, faRobot,faWrench,faChartSimple, faComments, faCodeBranch, faBrain, faCog, faChartPie, faLaptopCode,faGlobe,faBolt,faPencilRuler,faDraftingCompass,faPlug,faVial,faCheck,faEnvelopeOpenText,faBullseye,faUsers,faTrophy,faProjectDiagram,faCalendarAlt,faTasks,faLightbulb,faChalkboardTeacher, faHandshake,faChartLine, faSearch, faClipboardCheck, faChartBar, faTags, faExternalLinkAlt, faLink, faPenNib, faFileAlt, faServer, faCode, faTachometerAlt, faMobileAlt, faCogs, faMapMarkerAlt, faSearchLocation, faBuilding, faShieldAlt, faLock, faUserShield, faHdd, faCloudUploadAlt, faDatabase, faDesktop, faBell, faBug, faTools, faRocket, faNetworkWired, faHeadset, faLifeRing, faPen, faSync, faFilter, } from '@fortawesome/free-solid-svg-icons'
const servicesData = [


    

    {
        id: 1,
        title: "Création de Sites Web",
        subtitle: "Transformez vos idées en réalités digitales captivantes.",
        description: "La création de sites web chez PAÏTAWEB commence par une vision claire. Nous définissons vos objectifs, planifions l'architecture du site et élaborons une stratégie numérique adaptée. Notre équipe de développement expérimentée transforme ensuite ces plans en une réalité web exceptionnelle, en utilisant des technologies de pointe. Le résultat ? Des sites web \"performants\", \"réactifs\" et conçus pour \"captiver\" votre audience.",
        descriptionMobile: (
            <span className="customSpan">
              Chez PAÏTAWEB, nous créons des sites web <strong>adaptatifs</strong>, optimisés pour tous les
              appareils. Nos solutions <strong>personnalisées</strong> utilisent React et SASS pour garantir
              une performance et une navigation fluides, essentielles pour captiver vos visiteurs et
              convertir des prospects.
            </span>
          ),
                  descriptionTablet: "Description pour tablette.",
        descriptionDesktop: (
            <span>
              PAÏTAWEB excelle dans la création de sites web{" "}
              <strong>personnalisés</strong>, intégrant les dernières technologies
              front-end comme React et des styles élaborés avec SASS pour offrir des
              solutions <strong>complètes</strong> et <strong>dynamiques</strong>. Nous
              développons des sites <strong>adaptatifs</strong>, optimisés pour tous les
              appareils et conçus pour offrir une expérience utilisateur exceptionnelle,
              avec des parcours utilisateurs étudiés pour maximiser la{" "}
              <strong>conversion</strong> et l'<strong>engagement</strong>. Notre
              expertise couvre aussi bien les sites vitrine que les solutions e-commerce
              complexes, avec des systèmes de gestion de contenu (CMS){" "}
              <strong>personnalisés</strong> pour répondre à vos besoins spécifiques.
            </span>
          ),
                  image: process.env.PUBLIC_URL + '/img/code.jpg', // Ajustez le chemin si nécessaire
        icons: [faJsSquare, faReact, faHtml5],
        gradientColors: ['#2C5364', '#203A43', '#0F2027'],
        tags: ['🎨 Développement sur mesure', '🌐 Sites vitrines', '🛍️ Plateformes e-commerce', '🔧 Site réactifs et optimisés SEO', '👩‍💻 Applications web interactives'],
        tag: "Web",
        path:"creation-sites-web",

    },
    { 
        id: 2,
        title: "Design et UX/UI",
        subtitle: "Donnez vie à des expériences visuelles mémorables.",
        description:"Le design et l'expérience utilisateur (UX/UI) sont au cœur de tout ce que nous faisons. Chez PAÏTAWEB, nous créons des identités visuelles percutantes, des interfaces utilisateur intuitives et des chartes graphiques personnalisées. Notre objectif est de donner vie à des expériences visuelles mémorables qui laissent une impression durable et qui optimisent l'interaction entre votre audience et votre site web.",
        descriptionMobile: (
            <span className="customSpan">
              PAÏTAWEB développe des designs UI/UX qui allient <strong>esthétique</strong> et
              <strong>fonctionnalité</strong>. Nos interfaces sont créées pour améliorer l'<strong>engagement</strong>
              et offrir une navigation <strong>intuitive</strong>, avec des prototypes interactifs et des
              tests d'utilisabilité pour une expérience utilisateur sans faille.
            </span>
          ),
                  descriptionTablet: "Description pour tablette.",
        descriptionDesktop: (
            <span className="customSpan">
              Notre approche du design UI/UX chez <strong>PAÏTAWEB</strong> se concentre sur la création
              d'interfaces utilisateur qui ne sont pas seulement <strong>esthétiques</strong> mais aussi
              extrêmement <strong>fonctionnelles</strong> et <strong>intuitives</strong>. Nous utilisons des
              principes de design centrés sur l'utilisateur pour développer des interfaces
              qui améliorent l'<strong>engagement</strong> et simplifient la navigation. Nos services
              englobent la recherche utilisateur, la création de prototypes interactifs, et
              des tests d'utilisabilité pour assurer que chaque élément visuel est optimisé
              pour la <strong>performance</strong> et la satisfaction utilisateur.
            </span>
          ),
                  image: process.env.PUBLIC_URL + '/img/design.jpg', // Ajustez le chemin si nécessaire
        icons: [faSass, faFigma, faSketch],
        gradientColors: ['#2b5876', '#4e4376'],
        tags: ['👁️ Conception visuelle', '✊ Identité de marque', '🕶️ Expérience utilisateur immersive', '🪄 Interface utilisateur intuitive', '🖌️ Charte graphique personnalisée'],
        url: "https://www.paitaweb.fr/",
        tag: "Design",
        path:"design",


    },

    {
        id: 3,
        title: "Applications Mobiles",
        subtitle: "Innovez avec des applications mobiles exceptionnelles.",
        description:"Les applications mobiles sont l'avenir, et chez PAÏTAWEB, nous sommes à la pointe de cette révolution. Nous développons des applications iOS et Android exceptionnelles, en mettant l'accent sur l'expérience mobile de l'utilisateur. Que ce soit pour des applications natives, hybrides ou web, notre expertise garantit des performances optimales sur tous les appareils.",
        descriptionMobile: (
            <span className="customSpan">
              Maximisez votre <strong>visibilité</strong> avec l'optimisation SEO de PAÏTAWEB. Nous employons des
              stratégies complètes qui couvrent le <strong>contenu</strong>, la structure du site et la
              <strong>performance technique</strong> pour un meilleur classement sur les moteurs de recherche.
            </span>
          ),
                  descriptionTablet: "Description pour tablette.",
        descriptionDesktop: (
            <span className="customSpan">
              Chez PAÏTAWEB, l'optimisation SEO va au-delà des mots-clés : nous intégrons des
              stratégies de <strong>contenu</strong>, de <strong>structure de site</strong> et de <strong>performance technique</strong>
              pour améliorer le classement de votre site dans les moteurs de recherche. Nos experts
              SEO analysent et adaptent vos pages pour maximiser la <strong>visibilité</strong> et attirer
              un <strong>trafic qualifié</strong>, tout en s'assurant que votre site reste conforme aux
              dernières directives des moteurs de recherche.
            </span>
          ),
                  image: process.env.PUBLIC_URL + '/img/app.jpg', // Ajustez le chemin si nécessaire
        icons: [faApple, faAppStoreIos, faAndroid],
        gradientColors: ['#536976', '#292E49'],
        tags: ['📱 Développement iOS', '🤳 Applications hybrides', '🧑‍🎨 UX mobile optimisée', '⚡️ Performances mobile de pointe', ''],
        url: "https://www.paitaweb.fr/",
        tag: "Applications",
        path:"applications",


    },

    {
        id: 4,
        title: "Optimisation SEO",
        subtitle: "Optimisez votre visibilité en ligne avec notre expertise SEO",
        description:"L'optimisation SEO est essentielle pour augmenter la visibilité en ligne. Chez PAÏTAWEB, nous analysons et développons des stratégies SEO sur mesure. De l'optimisation on-page à la recherche de mots-clés en passant par la gestion des backlinks, nous veillons à ce que votre site web atteigne les sommets des résultats de recherche, attirant ainsi un trafic qualifié.",
        descriptionMobile: (
            <span className="customSpan">
              Optimisez la <strong>performance</strong> de votre site avec notre service de maintenance et
              support. PAÏTAWEB assure des mises à jour, des audits de sécurité, et un support technique
              rapide pour une expérience utilisateur optimale.
            </span>
          ),
                  descriptionTablet: "Description pour tablette.",
        descriptionDesktop: (
            <span className="customSpan">
              PAÏTAWEB offre une maintenance et un support technique pour garantir que votre site
              fonctionne sans interruption et à son maximum de <strong>performance</strong>. Nos services
              incluent des mises à jour régulières, des audits de sécurité, des optimisations de
              performance et un support technique rapide et efficace pour résoudre toute problématique,
              vous assurant ainsi une présence en ligne toujours active et <strong>performante</strong>.
            </span>
          ),
                  image: process.env.PUBLIC_URL + '/img/SEO.jpg', // Ajustez le chemin si nécessaire
        icons: [faGoogle, faKeyboard, faMagnifyingGlass],
        gradientColors: ['#283048', '#859398'],
        tags: ['🕵️‍♂️ Analyse et stratégie SEO', '⚙️ Optimisation on-page', '⌨️ Recherche de mots-clés', '🗣️ Backlinks et référencement', '📊 Rapports d"analyse et suivi'],
        url: "https://www.paitaweb.fr/",
        tag: "SEO",
        path:"SEO",


    },

    {
        id: 5,
        title: "Mainteance et Support",
        subtitle: "Conseils sur mesure pour des stratégies numériques gagnantes.",
        descriptionMobile: (
            <span className="customSpan">
              Bénéficiez de conseils experts avec PAÏTAWEB. Notre équipe vous aide à élaborer des
              stratégies et à mettre en œuvre des solutions technologiques qui propulsent votre
              <strong>croissance</strong>.
            </span>
          ),
                  descriptionTablet: "Description pour tablette.",
        descriptionDesktop: (
            <span className="customSpan">
              Chez PAÏTAWEB, nous offrons des consultations spécialisées pour aider les entreprises à
              naviguer dans le paysage numérique complexe. Nos experts en développement web et en
              stratégie digitale vous fournissent des conseils personnalisés pour élaborer et mettre en
              œuvre des solutions technologiques qui répondent à vos objectifs de <strong>croissance</strong>
              et d'<strong>innovation</strong>.
            </span>
          ),
                  description:"Chez PAÏTAWEB, notre engagement envers votre projet ne s'arrête pas au lancement. Nous offrons des services complets de maintenance et de support. Cela comprend des mises à jour régulières, une sécurité renforcée, un support technique réactif, un dépannage rapide et un monitoring continu pour garantir la fiabilité et la performance de votre site web à long terme.",
        image: process.env.PUBLIC_URL + '/img/broken.jpg', // Ajustez le chemin si nécessaire
        icons: [faRobot, faWrench, faChartSimple],
        gradientColors: ['#606c88', '#3f4c6b'],
        tags: ['🔋 Mises à jour régulières', '🔐 Sécurité renforcée', '☎️ Support technique réactif', '👨‍🔧 Dépannage rapide', '🖥️ Monitoring'],
        url: "https://www.paitaweb.fr/",
        tag: "Maintenance",
        path:"maintenance",


    },

    {
        id: 6,
        title: "Consultation et Conseil",
        subtitle: "Assurance de la fiabilité et de la performance à long terme.",
        descriptionMobile: (
            <span className="customSpan">
              Développez votre présence mobile avec des applications <strong>performantes</strong> et
              <strong>élégantes</strong> créées par PAÏTAWEB. Nos applications sont optimisées pour une
              expérience utilisateur exceptionnelle.
            </span>
          ),
                  descriptionTablet: "Description pour tablette.",
        descriptionDesktop: (
            <span className="customSpan">
              PAÏTAWEB développe des applications mobiles haut de gamme en utilisant Swift pour iOS,
              garantissant des applications rapides, <strong>réactives</strong> et esthétiquement plaisantes.
              Nous intégrons les meilleures pratiques en matière d'UX mobile pour créer des applications
              qui ne sont pas seulement fonctionnelles mais aussi <strong>engageantes</strong>, augmentant ainsi
              l'interaction utilisateur et la fidélisation.
            </span>
          ),
                  description: "Chez PAÏTAWEB, notre engagement envers votre succès va au-delà du développement web. Nous offrons des services de consultation et de conseil pour vous guider dans l'élaboration de stratégies numériques efficaces. Nos experts évaluent votre projet, réalisent des audits de site web, et fournissent des recommandations personnalisées pour maximiser votre impact en ligne. Nous sommes votre partenaire de confiance pour atteindre vos objectifs numériques.",
        image: process.env.PUBLIC_URL + '/img/consulting.jpg', // Ajustez le chemin si nécessaire
        icons: [faComments, faCodeBranch, faHandshake],
        gradientColors: ['#525252', '#3d72b4'],
        tags: ['♟️ Stratégies numériques', '📝 Evaluations de projets', '🤖 Audits de site web', '🤝 Recommandations personnalisées', '👨‍🎓 Accompagnement expert'],
        url: "https://www.paitaweb.fr/",
        tag:"Consultation",
        path:"strategie",


    },

    {
      id: 7,
      title: "Sites éducatifs",
      subtitle: "Éducation numérique interactive et efficace.",
      description: "Nous créons des plateformes éducatives en ligne qui facilitent l'apprentissage et l'enseignement. Nos solutions incluent des fonctionnalités interactives et des outils d'évaluation pour une expérience éducative complète.",
      descriptionMobile: (
          <span className="customSpan">
            Nous développons des sites éducatifs avec des fonctionnalités interactives pour enrichir l'expérience d'apprentissage, incluant des quiz et des forums.
          </span>
      ),
      descriptionTablet: "Description pour tablette.",
      descriptionDesktop: (
          <span>
            PAÏTAWEB développe des sites éducatifs interactifs qui{" "}
            <strong>facilitent l'apprentissage</strong> et l'enseignement. Nos
            solutions incluent des fonctionnalités comme des quiz, des forums, et des
            outils d'évaluation pour offrir une <strong>expérience éducative complète</strong>.
          </span>
      ),
      image: process.env.PUBLIC_URL + '/img/ServicesImages/edu.jpg',
      icons: [faJsSquare, faReact, faHtml5],
      gradientColors: ['#2C5364', '#203A43', '#0F2027'],
      tags: ['📚 Plateformes de cours en ligne', '🎓 Outils interactifs', '📝 Systèmes d’évaluation'],
      tag: "Web",
      path: "sites-educatifs",
  },
  {
      id: 8,
      title: "Sites événementiels",
      subtitle: "Organisez et gérez vos événements en ligne.",
      description: "Nos sites événementiels sont conçus pour promouvoir et gérer vos événements avec efficacité. Ils intègrent des fonctionnalités comme les inscriptions en ligne, les calendriers d'événements et les notifications.",
      descriptionMobile: (
          <span className="customSpan">
            Nos sites événementiels facilitent la gestion des événements avec des inscriptions en ligne et des calendriers interactifs.
          </span>
      ),
      descriptionTablet: "Description pour tablette.",
      descriptionDesktop: (
          <span>
            PAÏTAWEB conçoit des sites événementiels qui permettent de{" "}
            <strong>promouvoir</strong> et de <strong>gérer</strong> vos événements
            en ligne. Nos solutions incluent des fonctionnalités telles que les
            inscriptions en ligne, les calendriers d'événements, et les notifications.
          </span>
      ),
      image: process.env.PUBLIC_URL + '/img/ServicesImages/events.jpg',
      icons: [faJsSquare, faReact, faHtml5],
      gradientColors: ['#2C5364', '#203A43', '#0F2027'],
      tags: ['🎉 Gestion d’événements', '🗓️ Calendriers', '🔔 Notifications'],
      tag: "Web",
      path: "sites-evenementiels",
  },
  {
      id: 9,
      title: "Sites multilingues",
      subtitle: "Touchez une audience globale avec des sites multilingues.",
      description: "Nous développons des sites web multilingues qui permettent de toucher une audience internationale. Nos solutions incluent la gestion de contenu dans plusieurs langues et des fonctionnalités d'optimisation SEO pour chaque langue.",
      descriptionMobile: (
          <span className="customSpan">
            Nous créons des sites web multilingues pour vous permettre de toucher une audience mondiale, avec un contenu adapté et optimisé pour chaque langue.
          </span>
      ),
      descriptionTablet: "Description pour tablette.",
      descriptionDesktop: (
          <span>
            PAÏTAWEB développe des sites web multilingues qui permettent de{" "}
            <strong>toucher une audience globale</strong>. Nos solutions incluent la
            gestion de contenu dans plusieurs langues et des fonctionnalités
            d'optimisation SEO adaptées à chaque langue.
          </span>
      ),
      image: process.env.PUBLIC_URL + '/img/ServicesImages/multi.jpg',
      icons: [faJsSquare, faReact, faHtml5],
      gradientColors: ['#2C5364', '#203A43', '#0F2027'],
      tags: ['🌏 Contenu multilingue', '📈 SEO international', '🌐 Expérience utilisateur globale'],
      tag: "Web",
      path: "sites-multilingues",
  },
  {
      id: 10,
      title: "Intranets d'entreprise",
      subtitle: "Optimisez la communication interne avec des intranets personnalisés.",
      description: "Nous créons des intranets d'entreprise pour améliorer la communication et la collaboration interne. Nos solutions sont sécurisées et personnalisées selon les besoins spécifiques de votre entreprise.",
      descriptionMobile: (
          <span className="customSpan">
            Nous développons des intranets sécurisés et personnalisés pour améliorer la communication et la collaboration au sein de votre entreprise.
          </span>
      ),
      descriptionTablet: "Description pour tablette.",
      descriptionDesktop: (
          <span>
            PAÏTAWEB crée des intranets d'entreprise pour{" "}
            <strong>améliorer la communication</strong> et la{" "}
            <strong>collaboration</strong> interne. Nos solutions sont sécurisées
            et personnalisées selon les besoins spécifiques de votre entreprise.
          </span>
      ),
      image: process.env.PUBLIC_URL + '/img/ServicesImages/intranet.jpg',
      icons: [faJsSquare, faReact, faHtml5],
      gradientColors: ['#2C5364', '#203A43', '#0F2027'],
      tags: ['🔒 Sécurité', '💼 Communication interne', '🤝 Collaboration'],
      tag: "Web",
      path: "intranets-entreprise",
  },


  {
    id: 11,
    title: "Sites vitrines",
    subtitle: "Mettez en valeur votre entreprise avec des sites vitrines élégants.",
    description: "Nos sites vitrines sont conçus pour présenter votre entreprise de manière professionnelle et attrayante. Nous mettons en avant vos services, produits et valeurs pour captiver vos visiteurs.",
    descriptionMobile: (
        <span className="customSpan">
          Nos sites vitrines présentent votre entreprise de manière attrayante, en mettant en avant vos services et produits.
        </span>
    ),
    descriptionTablet: "Description pour tablette.",
    descriptionDesktop: (
        <span>
          PAÏTAWEB conçoit des sites vitrines qui{" "}
          <strong>mettent en valeur</strong> votre entreprise. Nous créons des
          designs professionnels et attrayants qui présentent vos services, produits,
          et valeurs pour captiver vos visiteurs.
        </span>
    ),
    image: process.env.PUBLIC_URL + '/img/ServicesImages/vitrine.jpg',
    icons: [faJsSquare, faReact, faHtml5],
    gradientColors: ['#2C5364', '#203A43', '#0F2027'],
    tags: ['🏢 Présentation professionnelle', '🖼️ Design attrayant', '📈 Mise en valeur des services'],
    tag: "Web",
    path: "sites-vitrines",
},
{
    id: 12,
    title: "Sites e-commerce",
    subtitle: "Boostez vos ventes avec des sites e-commerce performants.",
    description: "Nous développons des sites e-commerce qui offrent une expérience d'achat fluide et sécurisée. Nos solutions incluent des fonctionnalités de gestion de catalogue, de paiement en ligne, et d'optimisation pour les moteurs de recherche.",
    descriptionMobile: (
        <span className="customSpan">
          Nos sites e-commerce offrent une expérience d'achat fluide et sécurisée, avec des fonctionnalités de gestion de catalogue et de paiement en ligne.
        </span>
    ),
    descriptionTablet: "Description pour tablette.",
    descriptionDesktop: (
        <span>
          PAÏTAWEB développe des sites e-commerce performants qui{" "}
          <strong>boostent vos ventes</strong>. Nos solutions incluent des
          fonctionnalités de gestion de catalogue, de paiement en ligne sécurisé,
          et d'optimisation pour les moteurs de recherche.
        </span>
    ),
    image: process.env.PUBLIC_URL + '/img/ServicesImages/commerce.jpg',
    icons: [faJsSquare, faReact, faHtml5],
    gradientColors: ['#2C5364', '#203A43', '#0F2027'],
    tags: ['🛒 Gestion de catalogue', '💳 Paiement en ligne sécurisé', '📈 Optimisation SEO'],
    tag: "Web",
    path: "sites-ecommerce",
},
{
    id: 13,
    title: "Blogs et sites publics",
    subtitle: "Partagez vos idées et actualités avec le monde.",
    description: "Nous créons des blogs et des sites publics qui permettent de partager des idées, des actualités, et des contenus divers. Nos solutions incluent des outils de gestion de contenu et d'optimisation pour les réseaux sociaux.",
    descriptionMobile: (
        <span className="customSpan">
          Nos blogs et sites publics vous permettent de partager des idées et actualités, avec des outils de gestion de contenu et d'optimisation pour les réseaux sociaux.
        </span>
    ),
    descriptionTablet: "Description pour tablette.",
    descriptionDesktop: (
        <span>
          PAÏTAWEB crée des blogs et des sites publics qui vous permettent de{" "}
          <strong>partager vos idées</strong> et vos actualités. Nos solutions
          incluent des outils de gestion de contenu et d'optimisation pour les
          réseaux sociaux.
        </span>
    ),
    image: process.env.PUBLIC_URL + '/img/ServicesImages/blog.jpg',
    icons: [faJsSquare, faReact, faHtml5],
    gradientColors: ['#2C5364', '#203A43', '#0F2027'],
    tags: ['📝 Gestion de contenu', '📢 Partage sur les réseaux sociaux', '🌐 Diffusion d’actualités'],
    tag: "Web",
    path: "blogs-sites-publics",
},
{
    id: 14,
    title: "Portfolios",
    subtitle: "Présentez vos travaux avec des portfolios percutants.",
    description: "Nous créons des portfolios en ligne pour présenter vos travaux, projets, et réalisations de manière attrayante. Nos designs sont optimisés pour mettre en valeur vos compétences et votre créativité.",
    descriptionMobile: (
        <span className="customSpan">
          Nos portfolios en ligne mettent en valeur vos travaux et réalisations, avec des designs optimisés pour présenter vos compétences et votre créativité.
        </span>
    ),
    descriptionTablet: "Description pour tablette.",
    descriptionDesktop: (
        <span>
          PAÏTAWEB crée des portfolios en ligne qui{" "}
          <strong>présentent vos travaux</strong> et réalisations de manière
          attrayante. Nos designs sont optimisés pour mettre en valeur vos
          compétences et votre créativité.
        </span>
    ),
    image: process.env.PUBLIC_URL + '/img/ServicesImages/portfolio.jpg',
    icons: [faJsSquare, faReact, faHtml5],
    gradientColors: ['#2C5364', '#203A43', '#0F2027'],
    tags: ['🎨 Présentation visuelle', '📁 Mise en valeur des projets', '💼 Design moderne'],
    tag: "Web",
    path: "portfolios",
},
{
    id: 15,
    title: "Landing pages",
    subtitle: "Attirez et convertissez avec des landing pages efficaces.",
    description: "Nous concevons des landing pages optimisées pour attirer et convertir les visiteurs. Nos pages sont créées avec des appels à l'action clairs et des designs attrayants pour maximiser les conversions.",
    descriptionMobile: (
        <span className="customSpan">
          Nos landing pages sont conçues pour maximiser les conversions avec des appels à l'action clairs et des designs attrayants.
        </span>
    ),
    descriptionTablet: "Description pour tablette.",
    descriptionDesktop: (
        <span>
          PAÏTAWEB conçoit des landing pages optimisées pour{" "}
          <strong>attirer</strong> et <strong>convertir</strong> les visiteurs.
          Nos pages sont créées avec des appels à l'action clairs et des designs
          attrayants pour maximiser les conversions.
        </span>
    ),
    image: process.env.PUBLIC_URL + '/img/ServicesImages/landing.jpg',
    icons: [faJsSquare, faReact, faHtml5],
    gradientColors: ['#2C5364', '#203A43', '#0F2027'],
    tags: ['🚀 Conversion optimale', '📣 Appels à l’action clairs', '🌟 Design attrayant'],
    tag: "Web",
    path: "landing-pages",
},
{
  id: 16,
  title: "Wireframing",
  subtitle: "Création de maquettes filaires pour structurer les interfaces utilisateur.",
  description: "Nous créons des maquettes filaires pour structurer vos interfaces utilisateur de manière optimale. Cela permet de définir l'architecture de votre site ou application avant de passer à la phase de design graphique.",
  descriptionMobile: (
      <span className="customSpan">
        Nous réalisons des maquettes filaires pour structurer vos interfaces utilisateur, assurant une architecture claire et fonctionnelle avant le design graphique.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB crée des maquettes filaires pour{" "}
        <strong>structurer vos interfaces utilisateur</strong> de manière
        optimale. Cela permet de définir l'architecture de votre site ou
        application avant de passer à la phase de design graphique.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/wireframe.jpg',
  icons: [faFigma, faSketch, faHtml5],
  gradientColors: ['#6a11cb', '#2575fc'],
  tags: ['📐 Structuration claire', '🖊️ Maquettes fonctionnelles', '🔧 Définition d’architecture'],
  tag: "Design",
  path: "wireframing",
},
{
  id: 17,
  title: "Prototypage",
  subtitle: "Développement de prototypes interactifs pour tester l’expérience utilisateur.",
  description: "Nous développons des prototypes interactifs pour tester et affiner l'expérience utilisateur. Ces prototypes permettent de valider les concepts et de réaliser des ajustements avant le développement final.",
  descriptionMobile: (
      <span className="customSpan">
        Nos prototypes interactifs permettent de tester et d’affiner l’expérience utilisateur, validant les concepts avant le développement final.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB développe des prototypes interactifs pour{" "}
        <strong>tester et affiner l'expérience utilisateur</strong>. Ces
        prototypes permettent de valider les concepts et de réaliser des
        ajustements avant le développement final.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/prototype.jpg',
  icons: [faFigma, faSketch, faReact],
  gradientColors: ['#ff758c', '#ff7eb3'],
  tags: ['🖥️ Prototypes interactifs', '🔍 Validation de concepts', '🔧 Ajustements UX'],
  tag: "Design",
  path: "prototypage",
},
{
  id: 18,
  title: "Design d’interface",
  subtitle: "Conception des interfaces graphiques attrayantes et fonctionnelles.",
  description: "Nous concevons des interfaces graphiques attrayantes et fonctionnelles pour vos sites web et applications. Nos designs sont centrés sur l'utilisateur et optimisés pour offrir une expérience fluide.",
  descriptionMobile: (
      <span className="customSpan">
        Nos designs d’interface sont attrayants et fonctionnels, centrés sur l’utilisateur pour offrir une expérience fluide.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB conçoit des interfaces graphiques{" "}
        <strong>attrayantes et fonctionnelles</strong> pour vos sites web et
        applications. Nos designs sont centrés sur l'utilisateur et optimisés
        pour offrir une expérience fluide.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/interface.jpg',
  icons: [faFigma, faSketch, faHtml5],
  gradientColors: ['#f953c6', '#b91d73'],
  tags: ['🎨 Design attrayant', '⚙️ Interface fonctionnelle', '🌟 Expérience utilisateur fluide'],
  tag: "Design",
  path: "design-interface",
},
{
  id: 19,
  title: "Design responsive",
  subtitle: "Création de designs adaptatifs pour différentes tailles d’écran.",
  description: "Nous créons des designs responsive qui s'adaptent parfaitement à toutes les tailles d'écran. Nos solutions assurent une expérience utilisateur cohérente et optimisée, quel que soit l'appareil utilisé.",
  descriptionMobile: (
      <span className="customSpan">
        Nos designs responsive s’adaptent à toutes les tailles d’écran, assurant une expérience utilisateur cohérente et optimisée sur tous les appareils.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB crée des designs responsive qui{" "}
        <strong>s'adaptent parfaitement à toutes les tailles d'écran</strong>.
        Nos solutions assurent une expérience utilisateur cohérente et optimisée,
        quel que soit l'appareil utilisé.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/responsive.jpg',
  icons: [faFigma, faSketch, faHtml5],
  gradientColors: ['#00c6ff', '#0072ff'],
  tags: ['📱 Adaptabilité', '🖥️ Expérience cohérente', '🌐 Optimisation multi-appareil'],
  tag: "Design",
  path: "design-responsive",
},
{
  id: 20,
  title: "Recherche utilisateur",
  subtitle: "Réalisation d’études et d’analyses des besoins utilisateurs.",
  description: "Nous réalisons des études et des analyses approfondies des besoins utilisateurs pour informer la conception de vos produits. Nos méthodes incluent des interviews, des sondages et des tests utilisateurs.",
  descriptionMobile: (
      <span className="customSpan">
        Nous menons des études et analyses des besoins utilisateurs pour informer la conception, incluant interviews, sondages et tests utilisateurs.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB réalise des études et des analyses approfondies des{" "}
        <strong>besoins utilisateurs</strong> pour informer la conception de
        vos produits. Nos méthodes incluent des interviews, des sondages et
        des tests utilisateurs.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/search.jpg',
  icons: [faBrain, faComments, faMagnifyingGlass],
  gradientColors: ['#ff7e5f', '#feb47b'],
  tags: ['🧐 Études utilisateurs', '📝 Sondages', '🔍 Tests utilisateurs'],
  tag: "Design",
  path: "recherche-utilisateur",
},
{
  id: 26,
  title: "Audit SEO",
  subtitle: "Analyse complète du site web pour identifier les opportunités d’amélioration.",
  description: "Nous réalisons un audit SEO complet pour identifier les opportunités d'amélioration de votre site web. Cela inclut l'analyse des performances techniques, des contenus et de l'architecture du site pour optimiser son référencement.",
  descriptionMobile: (
      <span className="customSpan">
        Nous effectuons un audit SEO complet pour identifier les opportunités d'amélioration de votre site web, incluant performances techniques, contenus et architecture.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB réalise un audit SEO complet pour{" "}
        <strong>identifier les opportunités d'amélioration</strong> de votre
        site web. Cela inclut l'analyse des performances techniques, des contenus,
        et de l'architecture du site pour optimiser son référencement.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/auditseo.jpg',
  icons: [faChartLine, faSearch, faClipboardCheck],
  gradientColors: ['#2C5364', '#203A43', '#0F2027'],
  tags: ['🔍 Analyse technique', '📄 Évaluation de contenu', '🔧 Optimisation de l’architecture'],
  tag: "SEO",
  path: "audit-seo",
},
{
  id: 27,
  title: "Recherche de mots-clés",
  subtitle: "Identification des mots-clés pertinents pour le contenu.",
  description: "Nous identifions les mots-clés pertinents pour votre contenu afin d'améliorer votre positionnement dans les résultats des moteurs de recherche. Notre approche inclut l'analyse de la concurrence et des tendances de recherche.",
  descriptionMobile: (
      <span className="customSpan">
        Nous identifions les mots-clés pertinents pour votre contenu, améliorant votre positionnement dans les résultats de recherche, avec analyse de la concurrence et des tendances.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB identifie les mots-clés pertinents pour{" "}
        <strong>votre contenu</strong> afin d'améliorer votre positionnement
        dans les résultats des moteurs de recherche. Notre approche inclut
        l'analyse de la concurrence et des tendances de recherche.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/keywords.jpg',
  icons: [faSearch, faClipboardCheck, faChartBar],
  gradientColors: ['#6a11cb', '#2575fc'],
  tags: ['🔍 Identification de mots-clés', '📊 Analyse de concurrence', '📈 Suivi des tendances'],
  tag: "SEO",
  path: "recherche-mots-cles",
},
{
  id: 28,
  title: "Optimisation on-page",
  subtitle: "Optimisation des balises HTML, des titres et des méta descriptions.",
  description: "Nous optimisons les éléments on-page de votre site web, tels que les balises HTML, les titres, et les méta descriptions, pour améliorer la visibilité et la pertinence de votre site dans les résultats des moteurs de recherche.",
  descriptionMobile: (
      <span className="customSpan">
        Nous optimisons les éléments on-page de votre site, tels que balises HTML, titres, et méta descriptions, pour améliorer la visibilité et la pertinence dans les résultats de recherche.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB optimise les éléments on-page de votre site web, tels que{" "}
        <strong>les balises HTML</strong>, les titres, et les méta descriptions,
        pour améliorer la visibilité et la pertinence de votre site dans les
        résultats des moteurs de recherche.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/onpage.jpg',
  icons: [faHtml5, faTags, faClipboardCheck],
  gradientColors: ['#ff758c', '#ff7eb3'],
  tags: ['🏷️ Optimisation des balises', '🔠 Titres pertinents', '📄 Méta descriptions optimisées'],
  tag: "SEO",
  path: "optimisation-on-page",
},
{
  id: 29,
  title: "Optimisation off-page",
  subtitle: "Stratégies de backlinking pour améliorer l’autorité du site.",
  description: "Nous développons des stratégies de backlinking pour améliorer l'autorité de votre site web. Cela inclut l'obtention de liens de qualité à partir de sites externes, augmentant ainsi la crédibilité et la visibilité de votre site.",
  descriptionMobile: (
      <span className="customSpan">
        Nous développons des stratégies de backlinking pour améliorer l'autorité de votre site web, obtenant des liens de qualité à partir de sites externes pour augmenter crédibilité et visibilité.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB développe des stratégies de backlinking pour{" "}
        <strong>améliorer l'autorité</strong> de votre site web. Cela inclut
        l'obtention de liens de qualité à partir de sites externes, augmentant
        ainsi la crédibilité et la visibilité de votre site.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/offpage.jpg',
  icons: [faExternalLinkAlt, faLink, faClipboardCheck],
  gradientColors: ['#00c6ff', '#0072ff'],
  tags: ['🔗 Stratégies de backlinking', '🌐 Liens de qualité', '📈 Autorité du site'],
  tag: "SEO",
  path: "optimisation-off-page",
},
{
  id: 30,
  title: "Création de contenu",
  subtitle: "Rédaction de contenus optimisés pour les moteurs de recherche.",
  description: "Nous rédigeons des contenus optimisés pour les moteurs de recherche, incluant des articles, des blogs, et des pages de site web. Nos contenus sont conçus pour améliorer votre visibilité et attirer un public ciblé.",
  descriptionMobile: (
      <span className="customSpan">
        Nous rédigeons des contenus optimisés pour les moteurs de recherche, incluant articles, blogs, et pages de site web, conçus pour améliorer visibilité et attirer un public ciblé.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB rédige des contenus optimisés pour{" "}
        <strong>les moteurs de recherche</strong>, incluant des articles, des
        blogs, et des pages de site web. Nos contenus sont conçus pour améliorer
        votre visibilité et attirer un public ciblé.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/contentcreator.jpg',
  icons: [faPenNib, faFileAlt, faClipboardCheck],
  gradientColors: ['#ff7e5f', '#feb47b'],
  tags: ['✍️ Rédaction optimisée', '📄 Articles et blogs', '🌟 Contenu attractif'],
  tag: "SEO",
  path: "creation-contenu",
},

{
  id: 31,
  title: "SEO technique",
  subtitle: "Amélioration de la vitesse de chargement, des URLs et de la structure du site.",
  description: "Nous optimisons les aspects techniques de votre site web pour améliorer sa vitesse de chargement, la structure des URLs, et l'architecture générale du site. Cela inclut l'optimisation du code, la mise en cache, et la compression des ressources.",
  descriptionMobile: (
      <span className="customSpan">
        Nous optimisons les aspects techniques de votre site, incluant la vitesse de chargement, la structure des URLs, et l'architecture générale, pour améliorer les performances SEO.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB optimise les aspects techniques de votre site web pour{" "}
        <strong>améliorer sa vitesse de chargement</strong>, la structure des
        URLs, et l'architecture générale du site. Cela inclut l'optimisation du
        code, la mise en cache, et la compression des ressources.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/technicseo.jpg',
  icons: [faServer, faCode, faTachometerAlt],
  gradientColors: ['#2C5364', '#203A43', '#0F2027'],
  tags: ['🚀 Vitesse de chargement', '🔗 Optimisation des URLs', '🔧 Architecture du site'],
  tag: "SEO",
  path: "seo-technique",
},
{
  id: 32,
  title: "Optimisation mobile",
  subtitle: "Assurer que le site est entièrement optimisé pour les appareils mobiles.",
  description: "Nous assurons que votre site web est entièrement optimisé pour les appareils mobiles. Cela inclut la conception responsive, l'amélioration de la vitesse de chargement sur mobile, et l'optimisation des interfaces pour une expérience utilisateur fluide.",
  descriptionMobile: (
      <span className="customSpan">
        Nous optimisons votre site pour les appareils mobiles, incluant la conception responsive, l'amélioration de la vitesse de chargement et l'optimisation des interfaces pour une expérience utilisateur fluide.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB assure que votre site web est entièrement optimisé pour{" "}
        <strong>les appareils mobiles</strong>. Cela inclut la conception responsive,
        l'amélioration de la vitesse de chargement sur mobile, et l'optimisation
        des interfaces pour une expérience utilisateur fluide.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/mobileopti.jpg',
  icons: [faMobileAlt, faTachometerAlt, faCogs],
  gradientColors: ['#6a11cb', '#2575fc'],
  tags: ['📱 Conception responsive', '🚀 Vitesse mobile', '🌟 UX mobile optimisée'],
  tag: "SEO",
  path: "optimisation-mobile",
},
{
  id: 33,
  title: "Analyse des concurrents",
  subtitle: "Étude des sites concurrents pour identifier les opportunités SEO.",
  description: "Nous réalisons une analyse approfondie de vos concurrents pour identifier les opportunités SEO. Cela inclut l'évaluation de leurs stratégies de mots-clés, de backlinks, et de contenu pour développer une stratégie concurrentielle efficace.",
  descriptionMobile: (
      <span className="customSpan">
        Nous analysons vos concurrents pour identifier les opportunités SEO, évaluant leurs stratégies de mots-clés, backlinks et contenu pour développer une stratégie concurrentielle efficace.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB réalise une analyse approfondie de vos{" "}
        <strong>concurrents</strong> pour identifier les opportunités SEO.
        Cela inclut l'évaluation de leurs stratégies de mots-clés, de backlinks,
        et de contenu pour développer une stratégie concurrentielle efficace.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/concurrents.jpg',
  icons: [faSearch, faChartLine, faClipboardCheck],
  gradientColors: ['#ff758c', '#ff7eb3'],
  tags: ['🔍 Analyse concurrentielle', '📊 Stratégie de mots-clés', '🌐 Évaluation de backlinks'],
  tag: "SEO",
  path: "analyse-concurrents",
},
{
  id: 34,
  title: "SEO local",
  subtitle: "Optimisation pour les recherches locales et Google My Business.",
  description: "Nous optimisons votre site web pour les recherches locales et Google My Business. Cela inclut l'optimisation de votre profil Google My Business, l'obtention de citations locales, et l'amélioration de votre visibilité dans les résultats de recherche locaux.",
  descriptionMobile: (
      <span className="customSpan">
        Nous optimisons votre site pour les recherches locales et Google My Business, incluant l'optimisation de votre profil, l'obtention de citations locales et l'amélioration de la visibilité dans les résultats de recherche locaux.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB optimise votre site web pour{" "}
        <strong>les recherches locales</strong> et Google My Business.
        Cela inclut l'optimisation de votre profil Google My Business, l'obtention
        de citations locales, et l'amélioration de votre visibilité dans les
        résultats de recherche locaux.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/local.jpg',
  icons: [faMapMarkerAlt, faSearchLocation, faBuilding],
  gradientColors: ['#00c6ff', '#0072ff'],
  tags: ['📍 Optimisation locale', '🏢 Google My Business', '🌐 Citations locales'],
  tag: "SEO",
  path: "seo-local",
},
{
  id: 35,
  title: "Rapports SEO",
  subtitle: "Fourniture de rapports réguliers sur les performances SEO.",
  description: "Nous fournissons des rapports SEO réguliers pour suivre les performances de votre site web. Ces rapports incluent des analyses détaillées des métriques clés, des recommandations d'amélioration, et un suivi des progrès réalisés.",
  descriptionMobile: (
      <span className="customSpan">
        Nous fournissons des rapports SEO réguliers pour suivre les performances de votre site web, incluant analyses détaillées, recommandations et suivi des progrès.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB fournit des rapports SEO réguliers pour{" "}
        <strong>suivre les performances</strong> de votre site web.
        Ces rapports incluent des analyses détaillées des métriques clés,
        des recommandations d'amélioration, et un suivi des progrès réalisés.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/reports.jpg',
  icons: [faChartLine, faFileAlt, faClipboardCheck],
  gradientColors: ['#ff7e5f', '#feb47b'],
  tags: ['📊 Analyses détaillées', '📝 Recommandations', '📈 Suivi des performances'],
  tag: "SEO",
  path: "rapports-seo",
},

{
  id: 36,
  title: "Mises à jour de sécurité",
  subtitle: "Application des correctifs et des mises à jour de sécurité.",
  description: "Nous assurons la sécurité de votre site web en appliquant régulièrement les correctifs et mises à jour nécessaires. Cela garantit la protection contre les vulnérabilités et les attaques potentielles.",
  descriptionMobile: (
      <span className="customSpan">
        Nous appliquons régulièrement les correctifs et mises à jour de sécurité nécessaires pour protéger votre site contre les vulnérabilités et les attaques.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB assure la sécurité de votre site web en{" "}
        <strong>appliquant régulièrement les correctifs et mises à jour</strong>
        nécessaires. Cela garantit la protection contre les vulnérabilités et les
        attaques potentielles.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/update.jpg',
  icons: [faShieldAlt, faLock, faUserShield],
  gradientColors: ['#2C5364', '#203A43', '#0F2027'],
  tags: ['🔒 Sécurité renforcée', '🛡️ Protection contre les attaques', '🔧 Mises à jour régulières'],
  tag: "Maintenance",
  path: "mises-a-jour-securite",
},
{
  id: 37,
  title: "Sauvegardes régulières",
  subtitle: "Mise en place de sauvegardes régulières du site web.",
  description: "Nous mettons en place des sauvegardes régulières de votre site web pour assurer la récupération des données en cas de problème. Cela inclut des sauvegardes automatiques et manuelles pour une sécurité maximale.",
  descriptionMobile: (
      <span className="customSpan">
        Nous mettons en place des sauvegardes régulières de votre site pour assurer la récupération des données en cas de problème, incluant des sauvegardes automatiques et manuelles.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB met en place des <strong>sauvegardes régulières</strong>
        de votre site web pour assurer la récupération des données en cas de
        problème. Cela inclut des sauvegardes automatiques et manuelles pour
        une sécurité maximale.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/saves.jpg',
  icons: [faHdd, faCloudUploadAlt, faDatabase],
  gradientColors: ['#6a11cb', '#2575fc'],
  tags: ['💾 Sauvegardes automatiques', '☁️ Récupération de données', '🔐 Sécurité des données'],
  tag: "Maintenance",
  path: "sauvegardes-regulieres",
},
{
  id: 38,
  title: "Surveillance du site",
  subtitle: "Surveillance des performances et de la disponibilité du site.",
  description: "Nous surveillons en continu les performances et la disponibilité de votre site web pour détecter et résoudre rapidement tout problème. Cela inclut des alertes en temps réel et des rapports de performance réguliers.",
  descriptionMobile: (
      <span className="customSpan">
        Nous surveillons en continu les performances et la disponibilité de votre site pour détecter et résoudre rapidement tout problème, incluant des alertes en temps réel et des rapports de performance réguliers.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB surveille en continu les <strong>performances et la disponibilité</strong>
        de votre site web pour détecter et résoudre rapidement tout problème. Cela
        inclut des alertes en temps réel et des rapports de performance réguliers.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/monitoring.jpg',
  icons: [faDesktop, faChartLine, faBell],
  gradientColors: ['#ff758c', '#ff7eb3'],
  tags: ['🔍 Surveillance en temps réel', '📊 Rapports de performance', '🚨 Alertes immédiates'],
  tag: "Maintenance",
  path: "surveillance-site",
},
{
  id: 39,
  title: "Correction des bugs",
  subtitle: "Identification et correction des bugs et des problèmes techniques.",
  description: "Nous identifions et corrigeons les bugs et les problèmes techniques de votre site web. Notre équipe réactive s'assure que votre site fonctionne de manière optimale et sans interruption.",
  descriptionMobile: (
      <span className="customSpan">
        Nous identifions et corrigeons les bugs et les problèmes techniques de votre site, assurant un fonctionnement optimal et sans interruption.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB identifie et corrige les <strong>bugs et les problèmes techniques</strong>
        de votre site web. Notre équipe réactive s'assure que votre site fonctionne
        de manière optimale et sans interruption.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/bugs.jpg',
  icons: [faBug, faTools, faWrench],
  gradientColors: ['#00c6ff', '#0072ff'],
  tags: ['🐛 Correction de bugs', '🔧 Problèmes techniques', '⚙️ Fonctionnement optimal'],
  tag: "Maintenance",
  path: "correction-bugs",
},
{
  id: 40,
  title: "Optimisation des performances",
  subtitle: "Amélioration de la vitesse et des performances du site.",
  description: "Nous optimisons la vitesse et les performances de votre site web pour offrir une expérience utilisateur fluide et rapide. Cela inclut l'optimisation du code, des images, et des ressources pour réduire les temps de chargement.",
  descriptionMobile: (
      <span className="customSpan">
        Nous optimisons la vitesse et les performances de votre site, incluant l'optimisation du code, des images et des ressources pour réduire les temps de chargement.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB optimise la <strong>vitesse et les performances</strong> de votre
        site web pour offrir une expérience utilisateur fluide et rapide. Cela
        inclut l'optimisation du code, des images, et des ressources pour réduire
        les temps de chargement.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/performances.jpg',
  icons: [faRocket, faTachometerAlt, faChartLine],
  gradientColors: ['#ff7e5f', '#feb47b'],
  tags: ['🚀 Vitesse accrue', '🖼️ Optimisation des images', '⚡ Performances maximales'],
  tag: "Maintenance",
  path: "optimisation-performances",
},

{
  id: 41,
  title: "Gestion des serveurs",
  subtitle: "Administration et gestion des serveurs web.",
  description: "Nous administrons et gérons vos serveurs web pour garantir leur performance et leur sécurité. Cela inclut la configuration, la surveillance et la maintenance des serveurs pour assurer une disponibilité maximale.",
  descriptionMobile: (
      <span className="customSpan">
        Nous administrons et gérons vos serveurs web pour garantir leur performance et leur sécurité, incluant configuration, surveillance et maintenance.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB administre et gère vos <strong>serveurs web</strong> pour garantir leur
        performance et leur sécurité. Cela inclut la configuration, la surveillance
        et la maintenance des serveurs pour assurer une disponibilité maximale.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/servers.jpg',
  icons: [faServer, faNetworkWired, faShieldAlt],
  gradientColors: ['#2C5364', '#203A43', '#0F2027'],
  tags: ['🔧 Administration serveur', '📡 Surveillance continue', '🛡️ Sécurité serveur'],
  tag: "Maintenance",
  path: "gestion-serveurs",
},
{
  id: 42,
  title: "Support technique",
  subtitle: "Assistance technique pour les utilisateurs et les administrateurs.",
  description: "Nous offrons un support technique complet pour les utilisateurs et les administrateurs de votre site web. Notre équipe est disponible pour résoudre les problèmes techniques et fournir des conseils pour une utilisation optimale de vos outils numériques.",
  descriptionMobile: (
      <span className="customSpan">
        Nous offrons un support technique complet pour les utilisateurs et les administrateurs de votre site, résolvant les problèmes techniques et fournissant des conseils pour une utilisation optimale.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB offre un <strong>support technique complet</strong> pour les utilisateurs
        et les administrateurs de votre site web. Notre équipe est disponible pour
        résoudre les problèmes techniques et fournir des conseils pour une utilisation
        optimale de vos outils numériques.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/technicsupport.jpg',
  icons: [faHeadset, faLifeRing, faTools],
  gradientColors: ['#6a11cb', '#2575fc'],
  tags: ['💬 Assistance utilisateur', '🛠️ Résolution de problèmes', '📞 Support dédié'],
  tag: "Maintenance",
  path: "support-technique",
},
{
  id: 43,
  title: "Mises à jour de contenu",
  subtitle: "Ajout et mise à jour du contenu du site web.",
  description: "Nous gérons l'ajout et la mise à jour du contenu de votre site web pour garantir qu'il reste pertinent et à jour. Cela inclut la publication d'articles, de nouvelles pages, et la mise à jour des informations existantes.",
  descriptionMobile: (
      <span className="customSpan">
        Nous gérons l'ajout et la mise à jour du contenu de votre site pour garantir qu'il reste pertinent et à jour, incluant publication d'articles, nouvelles pages et mise à jour des informations.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB gère l'<strong>ajout et la mise à jour du contenu</strong> de votre site web
        pour garantir qu'il reste pertinent et à jour. Cela inclut la publication d'articles,
        de nouvelles pages, et la mise à jour des informations existantes.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/contentupdate.jpg',
  icons: [faPen, faFileAlt, faSync],
  gradientColors: ['#ff758c', '#ff7eb3'],
  tags: ['📝 Ajout de contenu', '📄 Mise à jour d’articles', '🔄 Actualisation régulière'],
  tag: "Maintenance",
  path: "mises-a-jour-contenu",
},
{
  id: 44,
  title: "Optimisation de la base de données",
  subtitle: "Nettoyage et optimisation des bases de données.",
  description: "Nous optimisons votre base de données pour améliorer la performance et l'efficacité de votre site web. Cela inclut le nettoyage des données inutiles, l'indexation, et la maintenance régulière pour assurer une rapidité d'accès aux données.",
  descriptionMobile: (
      <span className="customSpan">
        Nous optimisons votre base de données pour améliorer la performance et l'efficacité de votre site, incluant nettoyage des données inutiles, indexation et maintenance régulière.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB optimise votre <strong>base de données</strong> pour améliorer la
        performance et l'efficacité de votre site web. Cela inclut le nettoyage
        des données inutiles, l'indexation, et la maintenance régulière pour
        assurer une rapidité d'accès aux données.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/database.jpg',
  icons: [faDatabase, faFilter, faServer],
  gradientColors: ['#00c6ff', '#0072ff'],
  tags: ['🗄️ Nettoyage de données', '⚡ Performance améliorée', '🔍 Indexation efficace'],
  tag: "Maintenance",
  path: "optimisation-base-de-donnees",
},
{
  id: 45,
  title: "Audit de sécurité",
  subtitle: "Réalisation d’audits de sécurité pour identifier les vulnérabilités.",
  description: "Nous réalisons des audits de sécurité pour identifier les vulnérabilités de votre site web. Cela inclut l'évaluation des protocoles de sécurité, la détection des failles, et la recommandation de mesures correctives pour renforcer la sécurité.",
  descriptionMobile: (
      <span className="customSpan">
        Nous réalisons des audits de sécurité pour identifier les vulnérabilités de votre site, incluant évaluation des protocoles, détection des failles et recommandation de mesures correctives.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB réalise des <strong>audits de sécurité</strong> pour identifier
        les vulnérabilités de votre site web. Cela inclut l'évaluation des
        protocoles de sécurité, la détection des failles, et la recommandation
        de mesures correctives pour renforcer la sécurité.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/securityaudit.jpg',
  icons: [faShieldAlt, faSearch, faUserShield],
  gradientColors: ['#ff7e5f', '#feb47b'],
  tags: ['🛡️ Évaluation des protocoles', '🔍 Détection de failles', '📋 Mesures correctives'],
  tag: "Maintenance",
  path: "audit-securite",
},

{
  id: 46,
  title: "Applications natives",
  subtitle: "Développement d’applications pour iOS et Android.",
  description: "Nous développons des applications natives pour iOS et Android en utilisant les technologies les plus récentes. Cela garantit une performance optimale, une intégration complète avec les fonctionnalités de l'appareil, et une expérience utilisateur exceptionnelle.",
  descriptionMobile: (
      <span className="customSpan">
        Nous développons des applications natives pour iOS et Android, garantissant une performance optimale, une intégration complète avec les fonctionnalités de l'appareil, et une expérience utilisateur exceptionnelle.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB développe des <strong>applications natives</strong> pour iOS et Android
        en utilisant les technologies les plus récentes. Cela garantit une
        performance optimale, une intégration complète avec les fonctionnalités
        de l'appareil, et une expérience utilisateur exceptionnelle.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/nativesapp.jpg',
  icons: [faApple, faAndroid, faMobileAlt],
  gradientColors: ['#2C5364', '#203A43', '#0F2027'],
  tags: ['📱 iOS et Android', '🚀 Performance optimale', '🎯 Intégration complète'],
  tag: "Applications",
  path: "applications-natives",
},
{
  id: 47,
  title: "Applications hybrides",
  subtitle: "Création d’applications utilisant des technologies web (React Native, Flutter).",
  description: "Nous créons des applications hybrides en utilisant des technologies web comme React Native et Flutter. Cela permet de développer une seule application qui fonctionne sur plusieurs plateformes, offrant ainsi une solution économique et rapide.",
  descriptionMobile: (
      <span className="customSpan">
        Nous créons des applications hybrides en utilisant des technologies web comme React Native et Flutter, permettant de développer une seule application qui fonctionne sur plusieurs plateformes.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB crée des <strong>applications hybrides</strong> en utilisant des technologies web
        comme React Native et Flutter. Cela permet de développer une seule application qui
        fonctionne sur plusieurs plateformes, offrant ainsi une solution économique et rapide.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/hybridsapp.jpg',
  icons: [faCode, faLaptopCode, faMobileAlt],
  gradientColors: ['#6a11cb', '#2575fc'],
  tags: ['🌐 React Native', '⚡ Flutter', '💡 Solutions économiques'],
  tag: "Applications",
  path: "applications-hybrides",
},
{
  id: 48,
  title: "Applications web progressives (PWA)",
  subtitle: "Développement de PWA pour une expérience mobile optimisée.",
  description: "Nous développons des applications web progressives (PWA) qui offrent une expérience mobile optimisée. Les PWA combinent les meilleures fonctionnalités des applications web et mobiles, offrant ainsi une performance rapide, une fiabilité hors ligne et une installation facile.",
  descriptionMobile: (
      <span className="customSpan">
        Nous développons des applications web progressives (PWA) offrant une expérience mobile optimisée, combinant les meilleures fonctionnalités des applications web et mobiles.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB développe des <strong>applications web progressives (PWA)</strong> qui
        offrent une expérience mobile optimisée. Les PWA combinent les meilleures
        fonctionnalités des applications web et mobiles, offrant ainsi une performance
        rapide, une fiabilité hors ligne et une installation facile.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/webprogressive.jpg',
  icons: [faGlobe, faBolt, faMobileAlt],
  gradientColors: ['#ff758c', '#ff7eb3'],
  tags: ['📲 PWA', '⚡ Performance rapide', '📶 Fiabilité hors ligne'],
  tag: "Applications",
  path: "applications-web-progressives",
},
{
  id: 49,
  title: "Conception UI/UX mobile",
  subtitle: "Conception d’interfaces utilisateur spécifiques aux mobiles.",
  description: "Nous concevons des interfaces utilisateur spécifiques aux mobiles pour garantir une expérience utilisateur fluide et intuitive. Cela inclut la création de wireframes, de prototypes interactifs et de designs finaux adaptés aux différentes tailles d'écran.",
  descriptionMobile: (
      <span className="customSpan">
        Nous concevons des interfaces utilisateur spécifiques aux mobiles pour garantir une expérience utilisateur fluide et intuitive, incluant wireframes, prototypes interactifs et designs finaux adaptés aux différentes tailles d'écran.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB conçoit des <strong>interfaces utilisateur spécifiques aux mobiles</strong>
        pour garantir une expérience utilisateur fluide et intuitive. Cela inclut la création
        de wireframes, de prototypes interactifs et de designs finaux adaptés aux différentes
        tailles d'écran.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/mobileui.jpg',
  icons: [faPencilRuler, faDraftingCompass, faMobileAlt],
  gradientColors: ['#00c6ff', '#0072ff'],
  tags: ['🖌️ UI/UX mobile', '📱 Designs intuitifs', '🔍 Prototypes interactifs'],
  tag: "Applications",
  path: "conception-ui-ux-mobile",
},
{
  id: 50,
  title: "Intégration d’API",
  subtitle: "Intégration de services tiers via des API dans les applications mobiles.",
  description: "Nous intégrons des services tiers via des API dans vos applications mobiles pour enrichir leurs fonctionnalités. Cela inclut l'intégration de services de paiement, de géolocalisation, de réseaux sociaux, et bien plus encore.",
  descriptionMobile: (
      <span className="customSpan">
        Nous intégrons des services tiers via des API dans vos applications mobiles pour enrichir leurs fonctionnalités, incluant services de paiement, géolocalisation, réseaux sociaux et bien plus encore.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB intègre des <strong>services tiers via des API</strong> dans vos applications
        mobiles pour enrichir leurs fonctionnalités. Cela inclut l'intégration de services
        de paiement, de géolocalisation, de réseaux sociaux, et bien plus encore.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/api.jpg',
  icons: [faPlug, faNetworkWired, faMobileAlt],
  gradientColors: ['#ff7e5f', '#feb47b'],
  tags: ['🔌 Intégration API', '💳 Services de paiement', '📍 Géolocalisation'],
  tag: "Applications",
  path: "integration-api",
},

{
  id: 51,
  title: "Tests mobiles",
  subtitle: "Tests de fonctionnalité, de performance et d’utilisabilité des applications mobiles.",
  description: "Nous réalisons des tests complets pour vos applications mobiles, incluant des tests de fonctionnalité, de performance et d’utilisabilité. Cela garantit que vos applications fonctionnent parfaitement et offrent une expérience utilisateur optimale.",
  descriptionMobile: (
      <span className="customSpan">
        Nous réalisons des tests complets pour vos applications mobiles, incluant des tests de fonctionnalité, de performance et d’utilisabilité, garantissant une expérience utilisateur optimale.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB réalise des <strong>tests complets</strong> pour vos applications mobiles,
        incluant des tests de fonctionnalité, de performance et d’utilisabilité. Cela garantit
        que vos applications fonctionnent parfaitement et offrent une expérience utilisateur optimale.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/mobiletest.jpg',
  icons: [faVial, faMobileAlt, faCheck],
  gradientColors: ['#2C5364', '#203A43', '#0F2027'],
  tags: ['🔍 Tests de fonctionnalité', '⚡ Tests de performance', '🛠️ Tests d’utilisabilité'],
  tag: "Applications",
  path: "tests-mobiles",
},
{
  id: 52,
  title: "Mises à jour et maintenance",
  subtitle: "Gestion des mises à jour et de la maintenance des applications mobiles.",
  description: "Nous gérons les mises à jour et la maintenance de vos applications mobiles pour garantir leur bon fonctionnement et leur sécurité. Cela inclut l'application des correctifs de sécurité, l'ajout de nouvelles fonctionnalités, et la résolution des bugs.",
  descriptionMobile: (
      <span className="customSpan">
        Nous gérons les mises à jour et la maintenance de vos applications mobiles, incluant correctifs de sécurité, ajout de nouvelles fonctionnalités et résolution des bugs.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB gère les <strong>mises à jour et la maintenance</strong> de vos applications
        mobiles pour garantir leur bon fonctionnement et leur sécurité. Cela inclut l'application
        des correctifs de sécurité, l'ajout de nouvelles fonctionnalités, et la résolution des bugs.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/updatemobile.jpg',
  icons: [faSync, faWrench, faMobileAlt],
  gradientColors: ['#6a11cb', '#2575fc'],
  tags: ['🔄 Mises à jour', '🛠️ Maintenance', '🔧 Correctifs de sécurité'],
  tag: "Applications",
  path: "mises-a-jour-maintenance",
},
{
  id: 53,
  title: "Déploiement sur les stores",
  subtitle: "Publication et gestion des applications sur l’App Store et Google Play.",
  description: "Nous prenons en charge le déploiement de vos applications mobiles sur l'App Store et Google Play. Cela inclut la préparation des ressources nécessaires, la gestion des soumissions et des mises à jour, et la résolution des problèmes de conformité.",
  descriptionMobile: (
      <span className="customSpan">
        Nous prenons en charge le déploiement de vos applications mobiles sur l'App Store et Google Play, incluant préparation des ressources, gestion des soumissions et résolution des problèmes de conformité.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB prend en charge le <strong>déploiement de vos applications mobiles</strong> sur l'App Store et Google Play. Cela inclut la préparation des ressources nécessaires, la gestion des soumissions et des mises à jour, et la résolution des problèmes de conformité.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/stores.jpg',
  icons: [faApple, faGooglePlay, faCloudUploadAlt],
  gradientColors: ['#ff758c', '#ff7eb3'],
  tags: ['📲 App Store', '🤖 Google Play', '☁️ Gestion des soumissions'],
  tag: "Applications",
  path: "deploiement-stores",
},
{
  id: 54,
  title: "Notifications push",
  subtitle: "Mise en place de notifications push pour l’engagement utilisateur.",
  description: "Nous mettons en place des notifications push pour vos applications mobiles afin d'améliorer l'engagement utilisateur. Cela inclut la configuration des notifications, la gestion des segments d'audience, et l'analyse des résultats pour optimiser les campagnes.",
  descriptionMobile: (
      <span className="customSpan">
        Nous mettons en place des notifications push pour vos applications mobiles afin d'améliorer l'engagement utilisateur, incluant configuration des notifications, gestion des segments d'audience et analyse des résultats.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB met en place des <strong>notifications push</strong> pour vos applications mobiles afin d'améliorer l'engagement utilisateur. Cela inclut la configuration des notifications, la gestion des segments d'audience, et l'analyse des résultats pour optimiser les campagnes.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/push.jpg',
  icons: [faBell, faEnvelopeOpenText, faMobileAlt],
  gradientColors: ['#00c6ff', '#0072ff'],
  tags: ['🔔 Notifications push', '👥 Segments d’audience', '📊 Analyse des résultats'],
  tag: "Applications",
  path: "notifications-push",
},
{
  id: 55,
  title: "Analyse et suivi",
  subtitle: "Intégration d’outils d’analyse pour suivre l’utilisation et les performances.",
  description: "Nous intégrons des outils d'analyse dans vos applications mobiles pour suivre l'utilisation et les performances. Cela inclut l'installation et la configuration d'outils tels que Google Analytics, ainsi que la création de rapports pour vous aider à comprendre le comportement des utilisateurs et à optimiser l'application.",
  descriptionMobile: (
      <span className="customSpan">
        Nous intégrons des outils d'analyse dans vos applications mobiles pour suivre l'utilisation et les performances, incluant Google Analytics et création de rapports pour optimiser l'application.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB intègre des <strong>outils d'analyse</strong> dans vos applications mobiles pour
        suivre l'utilisation et les performances. Cela inclut l'installation et la configuration
        d'outils tels que Google Analytics, ainsi que la création de rapports pour vous aider à
        comprendre le comportement des utilisateurs et à optimiser l'application.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/suivimobile.jpg',
  icons: [faChartLine, faMobileAlt, faSearch],
  gradientColors: ['#ff7e5f', '#feb47b'],
  tags: ['📊 Outils d’analyse', '📈 Suivi des performances', '📝 Création de rapports'],
  tag: "Applications",
  path: "analyse-suivi",
},

{
  id: 56,
  title: "Audit technique",
  subtitle: "Réalisation d’audits techniques pour identifier les opportunités d’amélioration.",
  description: "Nous réalisons des audits techniques complets pour identifier les opportunités d'amélioration de vos systèmes existants. Cela inclut l'analyse des performances, de la sécurité, et des infrastructures technologiques pour proposer des recommandations concrètes.",
  descriptionMobile: (
      <span className="customSpan">
        Nous réalisons des audits techniques pour identifier les opportunités d'amélioration, incluant l'analyse des performances et de la sécurité.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB réalise des <strong>audits techniques complets</strong> pour identifier les opportunités d'amélioration de vos systèmes existants. Cela inclut l'analyse des performances, de la sécurité, et des infrastructures technologiques pour proposer des recommandations concrètes.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/technicalaudit.jpg',
  icons: [faSearch, faWrench, faShieldAlt],
  gradientColors: ['#2C5364', '#203A43', '#0F2027'],
  tags: ['🔍 Audit technique', '🔧 Analyse des performances', '🔐 Sécurité'],
  tag: "Consultation",
  path: "audit-technique",
},
{
  id: 57,
  title: "Stratégie digitale",
  subtitle: "Définition de stratégies digitales alignées avec les objectifs de l’entreprise.",
  description: "Nous définissons des stratégies digitales alignées avec les objectifs de votre entreprise pour maximiser votre présence en ligne et atteindre vos cibles. Cela inclut la planification des campagnes, l'optimisation des canaux numériques, et l'amélioration de l'engagement client.",
  descriptionMobile: (
      <span className="customSpan">
        Nous définissons des stratégies digitales pour maximiser votre présence en ligne, incluant la planification des campagnes et l'optimisation des canaux numériques.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB définit des <strong>stratégies digitales alignées</strong> avec les objectifs de votre entreprise pour maximiser votre présence en ligne et atteindre vos cibles. Cela inclut la planification des campagnes, l'optimisation des canaux numériques, et l'amélioration de l'engagement client.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/strategy.jpg',
  icons: [faBullseye, faChartLine, faUsers],
  gradientColors: ['#6a11cb', '#2575fc'],
  tags: ['📊 Stratégie digitale', '📈 Optimisation des canaux', '💼 Engagement client'],
  tag: "Consultation",
  path: "strategie-digitale",
},
{
  id: 58,
  title: "Analyse de la concurrence",
  subtitle: "Étude des concurrents pour informer les stratégies digitales.",
  description: "Nous réalisons des analyses de la concurrence pour identifier les forces et faiblesses de vos concurrents et informer vos stratégies digitales. Cela permet de mieux positionner votre entreprise sur le marché et de développer des avantages concurrentiels.",
  descriptionMobile: (
      <span className="customSpan">
        Nous réalisons des analyses de la concurrence pour identifier les forces et faiblesses de vos concurrents et informer vos stratégies digitales.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB réalise des <strong>analyses de la concurrence</strong> pour identifier les forces et faiblesses de vos concurrents et informer vos stratégies digitales. Cela permet de mieux positionner votre entreprise sur le marché et de développer des avantages concurrentiels.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/analyseconcu.jpg',
  icons: [faChartBar, faSearch, faTrophy],
  gradientColors: ['#ff758c', '#ff7eb3'],
  tags: ['🔍 Analyse concurrentielle', '💡 Stratégies digitales', '🏆 Avantages concurrentiels'],
  tag: "Consultation",
  path: "analyse-concurrence",
},

{
  id: 59,
  title: "Planification de projet",
  subtitle: "Élaboration de plans de projet détaillés et de feuilles de route.",
  description: "Nous élaborons des plans de projet détaillés et des feuilles de route pour assurer une gestion efficace et structurée de vos projets numériques. Cela inclut la définition des étapes clés, des délais, et des ressources nécessaires pour atteindre vos objectifs.",
  descriptionMobile: (
      <span className="customSpan">
        Nous élaborons des plans de projet détaillés et des feuilles de route pour une gestion efficace de vos projets numériques, incluant étapes clés, délais et ressources nécessaires.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB élabore des <strong>plans de projet détaillés</strong> et des feuilles de route pour assurer une gestion efficace et structurée de vos projets numériques. Cela inclut la définition des étapes clés, des délais, et des ressources nécessaires pour atteindre vos objectifs.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/projectplaning.jpg',
  icons: [faProjectDiagram, faCalendarAlt, faTasks],
  gradientColors: ['#00c6ff', '#0072ff'],
  tags: ['🗂️ Plans de projet', '📅 Feuilles de route', '🔍 Gestion structurée'],
  tag: "Consultation",
  path: "planification-projet",
},
{
  id: 60,
  title: "Choix technologique",
  subtitle: "Conseil sur les choix technologiques les plus adaptés aux besoins.",
  description: "Nous vous conseillons sur les choix technologiques les plus adaptés à vos besoins et à vos objectifs. Cela inclut l'évaluation des options technologiques, la recommandation des meilleures solutions et l'aide à la mise en œuvre.",
  descriptionMobile: (
      <span className="customSpan">
        Nous vous conseillons sur les choix technologiques les plus adaptés à vos besoins et objectifs, incluant évaluation des options, recommandations des meilleures solutions et aide à la mise en œuvre.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB vous conseille sur les <strong>choix technologiques</strong> les plus adaptés à vos besoins et à vos objectifs. Cela inclut l'évaluation des options technologiques, la recommandation des meilleures solutions et l'aide à la mise en œuvre.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/choix.jpg',
  icons: [faLightbulb, faCode, faCogs],
  gradientColors: ['#ff7e5f', '#feb47b'],
  tags: ['💡 Choix technologiques', '🔍 Évaluation des options', '📈 Mise en œuvre'],
  tag: "Consultation",
  path: "choix-technologique",
},
{
  id: 61,
  title: "Formation",
  subtitle: "Formation des équipes aux meilleures pratiques en développement web.",
  description: "Nous offrons des formations complètes pour vos équipes sur les meilleures pratiques en développement web. Cela inclut des sessions sur les technologies de pointe, les méthodologies agiles, et les outils de développement modernes.",
  descriptionMobile: (
      <span className="customSpan">
        Nous offrons des formations pour vos équipes sur les meilleures pratiques en développement web, incluant technologies de pointe, méthodologies agiles et outils modernes.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB offre des <strong>formations complètes</strong> pour vos équipes sur les meilleures pratiques en développement web. Cela inclut des sessions sur les technologies de pointe, les méthodologies agiles, et les outils de développement modernes.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/formation.jpg',
  icons: [faChalkboardTeacher, faLaptopCode, faTools],
  gradientColors: ['#2C5364', '#203A43', '#0F2027'],
  tags: ['📚 Formation', '💡 Meilleures pratiques', '🛠️ Technologies de pointe'],
  tag: "Consultation",
  path: "formation",
},

{
  id: 62,
  title: "Optimisation des processus",
  subtitle: "Amélioration des processus de développement et de gestion de projet.",
  description: "Nous optimisons vos processus de développement et de gestion de projet pour améliorer l'efficacité et la productivité. Cela inclut l'analyse des processus actuels, la mise en place de méthodologies agiles, et l'utilisation d'outils de gestion de projet.",
  descriptionMobile: (
      <span className="customSpan">
        Nous optimisons vos processus de développement et de gestion de projet pour améliorer l'efficacité et la productivité, incluant analyse des processus actuels, mise en place de méthodologies agiles et utilisation d'outils de gestion de projet.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB optimise vos <strong>processus de développement et de gestion de projet</strong> pour améliorer l'efficacité et la productivité. Cela inclut l'analyse des processus actuels, la mise en place de méthodologies agiles, et l'utilisation d'outils de gestion de projet.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/processus.jpg',
  icons: [faCog, faProjectDiagram, faChartLine],
  gradientColors: ['#6a11cb', '#2575fc'],
  tags: ['⚙️ Optimisation des processus', '📈 Amélioration de la productivité', '🔍 Méthodologies agiles'],
  tag: "Consultation",
  path: "optimisation-processus",
},
{
  id: 63,
  title: "Sécurité web",
  subtitle: "Conseil sur les meilleures pratiques en matière de sécurité web.",
  description: "Nous vous conseillons sur les meilleures pratiques en matière de sécurité web pour protéger vos sites et applications. Cela inclut la mise en place de mesures de sécurité, la formation de vos équipes, et la réalisation d'audits de sécurité réguliers.",
  descriptionMobile: (
      <span className="customSpan">
        Nous vous conseillons sur les meilleures pratiques en matière de sécurité web pour protéger vos sites et applications, incluant mise en place de mesures de sécurité, formation des équipes et audits réguliers.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB vous conseille sur les <strong>meilleures pratiques</strong> en matière de sécurité web pour protéger vos sites et applications. Cela inclut la mise en place de mesures de sécurité, la formation de vos équipes, et la réalisation d'audits de sécurité réguliers.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/websecurity.jpg',
  icons: [faShieldAlt, faLock, faUserShield],
  gradientColors: ['#ff758c', '#ff7eb3'],
  tags: ['🔒 Sécurité web', '🛡️ Meilleures pratiques', '🔍 Audits réguliers'],
  tag: "Consultation",
  path: "securite-web",
},
{
  id: 64,
  title: "Growth hacking",
  subtitle: "Mise en place de stratégies de growth hacking pour accélérer la croissance.",
  description: "Nous mettons en place des stratégies de growth hacking pour accélérer la croissance de votre entreprise. Cela inclut l'optimisation des canaux de marketing, l'expérimentation rapide, et l'analyse des données pour identifier les opportunités de croissance.",
  descriptionMobile: (
      <span className="customSpan">
        Nous mettons en place des stratégies de growth hacking pour accélérer la croissance de votre entreprise, incluant optimisation des canaux de marketing, expérimentation rapide, et analyse des données.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB met en place des <strong>stratégies de growth hacking</strong> pour accélérer la croissance de votre entreprise. Cela inclut l'optimisation des canaux de marketing, l'expérimentation rapide, et l'analyse des données pour identifier les opportunités de croissance.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/growth.jpg',
  icons: [faRocket, faLightbulb, faChartLine],
  gradientColors: ['#00c6ff', '#0072ff'],
  tags: ['🚀 Growth hacking', '📈 Optimisation marketing', '🔍 Analyse des données'],
  tag: "Consultation",
  path: "growth-hacking",
},
{
  id: 65,
  title: "Analyse de données",
  subtitle: "Utilisation des données pour informer les décisions stratégiques.",
  description: "Nous utilisons des analyses de données pour informer vos décisions stratégiques et optimiser vos opérations. Cela inclut la collecte, l'analyse et l'interprétation des données pour découvrir des insights actionnables.",
  descriptionMobile: (
      <span className="customSpan">
        Nous utilisons des analyses de données pour informer vos décisions stratégiques et optimiser vos opérations, incluant collecte, analyse et interprétation des données pour découvrir des insights actionnables.
      </span>
  ),
  descriptionTablet: "Description pour tablette.",
  descriptionDesktop: (
      <span>
        PAÏTAWEB utilise des <strong>analyses de données</strong> pour informer vos décisions stratégiques et optimiser vos opérations. Cela inclut la collecte, l'analyse et l'interprétation des données pour découvrir des insights actionnables.
      </span>
  ),
  image: process.env.PUBLIC_URL + '/img/ServicesImages/dataanalyse.jpg',
  icons: [faChartPie, faDatabase, faChartBar],
  gradientColors: ['#ff7e5f', '#feb47b'],
  tags: ['📊 Analyse de données', '🔍 Insights actionnables', '📈 Optimisation stratégique'],
  tag: "Consultation",
  path: "analyse-donnees",
},


];

const adaptiveTexts = {
    textServices: {
        desktop: `Que vous cherchiez à créer un site web innovant, à améliorer votre présence en ligne, ou à développer une application mobile, PAÏTAWEB est votre partenaire idéal. Notre expertise englobe le développement avec REACT, la conception UI/UX, la création de chartes graphiques sur mesure, l'optimisation SEO, et bien plus encore. Faites-nous confiance pour transformer vos idées en réalité digitale, avec créativité, performance et style.`,
        mobile: `PAÏTAWEB, votre partenaire pour des solutions web et mobiles innovantes. Découvrez notre expertise en développement REACT, UI/UX, SEO et plus encore. Transformons ensemble vos idées en réalités digitales.`
    },
    // Vous pouvez ajouter d'autres textes adaptatifs ici
};


export { servicesData, adaptiveTexts };