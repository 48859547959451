import React from 'react';
import parcoursData from '../../Data/parcoursData'; // Assurez-vous d'avoir importé les données depuis le bon chemin
import './Styles/B-DetailedParcours.scss'
import DetailedParcoursComponent from './Components/DetailedParcoursComponent/DetailedParcoursComponent';
const DetailedParcours = () => {
    return (
        <div className="detailed-parcours">
        <div className='headerParcoursDetailed'>
            <h2>Le parcours<br/>en détails</h2>
            <img src="/img/logo.png" alt="Globe" className="logo-parcours"/>
        </div>
          
            <div className="parcours-section">
            {parcoursData.map((data, index) => (
        <DetailedParcoursComponent key={index} {...data} />
      ))}            </div>
            {/* Répétez les sections pour d'autres services si nécessaire */}
        </div>
    );
};

export default DetailedParcours;
