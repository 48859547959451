import React from 'react';
import './DetailedParcoursComponent.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const DetailedParcoursComponent = ({ stepNumber, stepTitle, summary, tags, logo, images, illustration, icons, bgColor }) => {
  // Détermine si stepNumber est pair ou impair
  const isEven = stepNumber % 2 === 0;

  return (
    <div className={`detailed-parcours-component ${isEven ? 'even-style' : 'odd-style'}`} style={{ backgroundColor: bgColor }}>
      <div className="header">
        <div className='bloc1'>
          <div className='col1'>
            <div className='ligne1'>
              <h2 className="step-number">{stepNumber}</h2>
              <h3 className="step-title">{stepTitle}</h3>
            </div>
            <p className="summary">{summary}</p>
          </div>
          <div className='col2'>
            <ul className="tags">
              {tags.map((tag, index) => <li key={index}>{tag}</li>)}
            </ul>
          </div>
          <div className='col3'>
            <img src={logo} alt={`Logo de l'étape ${stepTitle}`} className="step-logo" />
          </div>
        </div>

        <div className='bloc2'>
          <div className="image-container">
            {images.map((image, index) => (
              <div key={index} className="image-block" style={{ backgroundImage: `url(${image})` }} role="img" aria-label={`Image de l'étape ${stepTitle} ${index + 1}`}></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailedParcoursComponent;