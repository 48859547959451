import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Slider from 'react-slick';
import PortfolioData from '../../Data/PortfolioData';
import Modal from 'react-modal';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Styles/C-ProjectDetailPage.scss';
import './Styles/C-ProjectDetailPageMobile.scss';

const ProjectDetailPage = () => {
  let { projectId } = useParams();
  const project = PortfolioData.find(p => p.path === projectId);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!project) {
    return <div>Projet non trouvé</div>;
  }

  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: true,
    draggable: true,
  };

  const openModal = (image, index) => {
    setSelectedImage(image);
    setSelectedImageIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalIsOpen(false);
  };

  const showPrevImage = () => {
    const newIndex = (selectedImageIndex - 1 + project.photos.length) % project.photos.length;
    setSelectedImage(project.photos[newIndex]);
    setSelectedImageIndex(newIndex);
  };

  const showNextImage = () => {
    const newIndex = (selectedImageIndex + 1) % project.photos.length;
    setSelectedImage(project.photos[newIndex]);
    setSelectedImageIndex(newIndex);
  };

  return (
    <section id='blocProjetSolo'>
      <Helmet>
        <title>{`${project.title} - Projet - PAÏTAWEB`}</title>
        <meta name="description" content={project.contentShort} />
        <meta name="keywords" content={project.keyPhrases.join(', ')} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`https://www.paitaweb.fr/projects/${projectId}`} />
      </Helmet>
      <div className='project-detail-page'>
        <img src={project.logo} alt="Logo du projet" className='logoProjetPortfolio' loading="lazy" />
        <div className='project-detail-page-ligne1'>
          <div className='project-detail-page-col1'>
            <h2>{project.title}</h2>
            <a className='projectUrl' href={project.url} target="_blank" rel="noopener noreferrer">Voir le site web</a>
            <p>{project.content}</p>
          </div>
          <div className='project-detail-page-col2'>
            <ul>
              {project.keyPhrases.map((phrase, index) => (
                <li key={index} style={{ background: project.gradient }}>{phrase}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className='project-detail-page-ligne2'>
          {isMobile ? (
            <Slider {...settings}>
              {project.photos.map((photo, index) => (
                <div key={index} onClick={() => openModal(photo, index)}>
                  <img src={photo} alt={`Photo ${index + 1}`} loading="lazy" />
                </div>
              ))}
            </Slider>
          ) : (
            <div className="masonry-gallery">
              {project.photos.map((photo, index) => (
                <div key={index} className="masonry-item" onClick={() => openModal(photo, index)}>
                  <img src={photo} alt={`Photo ${index + 1}`} loading="lazy" />
                </div>
              ))}
            </div>
          )}
        </div>
        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          contentLabel="Image Enlarged"
          className="modal"
          overlayClassName="overlay"
        >
          <button className="close-button" onClick={closeModal}>×</button>
          <button className="navigation-button prev" onClick={showPrevImage}>❮</button>
          {selectedImage && <img src={selectedImage} alt="Enlarged view" />}
          <button className="navigation-button next" onClick={showNextImage}>❯</button>
        </Modal>
      </div>
    </section>
  );
};

export default ProjectDetailPage;