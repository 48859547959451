const cardInfo = [
  {
    title: "Explorer",
    fullTitle: "1",
    iconPath: "/img/1.svg",
    description: "Nous explorons sans cesse les nouvelles technologies et tendances pour anticiper les besoins futurs du web et offrir des solutions avant-gardistes",
  },
  {
    title: "Créer",
    fullTitle: "2",
    iconPath: "/img/2.svg",
    description: "Créer avec créativité et précision des solutions numériques qui répondent non seulement aux exigences des clients mais qui élèvent également leurs attentes.",
  },
  {
    title: "Adapter",
    fullTitle: "3",
    iconPath: "/img/3.svg",
    description: "Adapter nos approches et solutions pour répondre efficacement à l'évolution des marchés et aux besoins spécifiques de chaque client.",
  },
  {
    title: "Apprendre",
    fullTitle: "4",
    iconPath: "/img/4.svg",
    description: "Nous valorisons l'apprentissage continu pour rester à la pointe du progrès, garantissant que nos équipes sont toujours prêtes à relever de nouveaux défis.",
  },
  {
    title: "Innover",
    fullTitle: "5",
    iconPath: "/img/5.svg",
    description: "Innover constamment pour développer des solutions qui non seulement répondent aux attentes actuelles mais définissent les standards de demain.",
  },
  {
    title: "Partager",
    fullTitle: "6",
    iconPath: "/img/6.svg",
    description: "Partager nos connaissances et découvertes pour enrichir la communauté, favorisant un écosystème numérique plus inclusif et collaboratif.",
  },
];

export default cardInfo;
