import React, { useRef, useEffect, useState, useCallback, memo } from 'react';
import gsap from 'gsap';
import './Styles/Philo.scss';
import './Styles/PhiloTablette.scss';
import './Styles/PhiloMobile.scss';
import cardInfo from '../../Data/PhiloData'; // Import des données depuis PhiloData.js

const PhiloCard = memo(({ title, iconPath, description, isFlipped, onClick }) => {
  const cardRef = useRef(null);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    const isMobileOrTablet = window.matchMedia("(max-width: 1024px)").matches;

    if (isMobileOrTablet) {
      return; // Ne rien faire si l'appareil est un mobile ou une tablette
    }

    const onMouseMove = (e) => {
      if (!cardRef.current) return;

      const box = cardRef.current.getBoundingClientRect();
      const x = box.left + (box.width * 0.5);
      const y = box.top + (box.height * 0.5);
      const hoverArea = hover ? 0.7 : 0.5;
      const mouseX = e.clientX - x;
      const mouseY = e.clientY - y;
      const distance = Math.sqrt(mouseX ** 2 + mouseY ** 2);

      if (distance < (box.width * hoverArea)) {
        if (!hover) setHover(true);
        gsap.to(cardRef.current, {
          x: mouseX * 0.4,
          y: mouseY * 0.4,
          scale: 1.15,
          ease: 'power2.out',
          duration: 0.4,
        });
      }
    };

    const onMouseLeave = () => {
      if (hover) setHover(false);
      gsap.to(cardRef.current, {
        x: 0,
        y: 0,
        scale: 1,
        ease: 'power2.out',
        duration: 0.4,
      });
    };

    const cardEl = cardRef.current;
    cardEl.addEventListener('mousemove', onMouseMove);
    cardEl.addEventListener('mouseleave', onMouseLeave);

    return () => {
      if (cardEl) {
        cardEl.removeEventListener('mousemove', onMouseMove);
        cardEl.removeEventListener('mouseleave', onMouseLeave);
      }
    };
  }, [hover]);

  useEffect(() => {
    if (isFlipped) {
      gsap.to(cardRef.current, {
        x: 0,
        y: 0,
        scale: 1,
        ease: 'power2.out',
        duration: 0.4,
      });
    }
  }, [isFlipped]);

  return (
    <div ref={cardRef} className={`philo-card ${isFlipped ? 'flipped' : ''}`} onClick={onClick}>
      <div className="card-front">
        <img className="card-icon" src={process.env.PUBLIC_URL + iconPath} alt={title} />
        <h5>{title}</h5>
      </div>
      <div className="card-back">
        <div className="card-back-title">
          <h6>{title}</h6>
          <img className="card-icon" src={process.env.PUBLIC_URL + iconPath} alt={title} />
        </div>
        <p>{description}</p>
      </div>
    </div>
  );
});

const Philo = () => {
  // La première carte est déjà retournée par défaut
  const [flippedStates, setFlippedStates] = useState(
    [true, ...Array(cardInfo.length - 1).fill(false)]
  );

  const toggleFlip = useCallback((index) => {
    setFlippedStates((prevState) => {
      const newFlippedStates = [...prevState];
      newFlippedStates[index] = !newFlippedStates[index];
      return newFlippedStates;
    });
  }, []);

  return (
    <section className="philo-section">
      <div className="sectionHeader">
      <img src="/img/eye2.gif" alt="Bulle avec le texte blah blah blah" className="iconeSectionHeader"/>

        <h2>NOTRE VISION</h2>
        <h3>Pour le Web de l'Avenir : Accessible, Impactant</h3>
        <p className='blocInfoSection'>
  Chez <strong>PAÏTAWEB</strong>, nous nous engageons à construire un <strong>futur numérique</strong> <strong>accessible</strong>, <strong>éthique</strong> et <strong>impactant</strong>.
  Notre philosophie repose sur la création de solutions digitales qui favorisent une <strong>intégration totale</strong>,
  respectent les <strong>normes éthiques</strong> et produisent un <strong>impact positif</strong> sur la société et l'environnement.
</p>
      </div>
      <div className="philo-grid">
        {cardInfo.map((card, index) => (
          <PhiloCard
            key={index}
            title={card.title}
            fullTitle={card.title}
            iconPath={card.iconPath}
            description={card.description}
            isFlipped={flippedStates[index]}
            onClick={() => toggleFlip(index)}
          />
        ))}
      </div>
    </section>
  );
};

export default Philo;