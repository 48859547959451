import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './PortfolioCarousel.scss';
import PortfolioData from '../../../../Data/PortfolioData';
import ProjectCard from '../../ProjectCard';

const PortfolioCarousel = () => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    dots: true,
    autoplay: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024, // Correspond à la taille des tablettes
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768, // Correspond à la taille des mobiles
        settings: {
          slidesToShow: 1,
          centerPadding: '0px', // Adjust this if needed
          adaptiveHeight: true,
        },
      },
    ],
  };

  return (
    <div className='portfolio-carousel-wrapper'>
      <Slider {...settings}>
        {PortfolioData.map((card) => (
          <div key={card.id} className="carousel-item-wrapper">
            <ProjectCard card={card} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default PortfolioCarousel;