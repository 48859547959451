import React from 'react';
import './Footer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faBehance, faLinkedin } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo" onClick={scrollToTop} style={{ cursor: 'pointer' }}>
          <img src="/img/logoblanc.svg" alt="Logo PAÏTAWEB" className="footer-logo-image" />
          <p>&copy; 2024 Tous droits réservés</p>
        </div>

        <div className='footer-contact'>
   
        </div>

        <div className="footer-social">
          <a href="mailto:example@example.com" aria-label="Envoyer un email">
            <FontAwesomeIcon icon={faEnvelope} size="2x" />
          </a>
          <a href="https://www.behance.net" target="_blank" rel="noopener noreferrer" aria-label="Behance">
            <FontAwesomeIcon icon={faBehance} size="2x" />
          </a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
          </a>
          <a href="tel:+1234567890" aria-label="Téléphoner">
            <FontAwesomeIcon icon={faPhone} size="2x" />
          </a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;