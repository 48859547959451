import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import './App.scss';
import Navbar from './Components/Common/NavBar/NavBar';
import Footer from './Components/Common/Footer/Footer';
import LandingPage from './Components/Common/LandingPage/LandingPage';
import DetailedServices from './Components/02a-DetailledServices/DetailedServices';
import DetailedParcours from './Components/03-Parcours/B-DetailedParcours';
import SpecificServicePage from './Components/02b-SpecificServicePage/SpecificServicePage';
import ProjectDetailPage from './Components/05-Portfolio/C-ProjectDetailPage';
import AllProjectsPage from './Components/05-Portfolio/B-ProjectsPage';
import HomePage from './Components/01-HomePage/HomePage';
import Services from './Components/02-Services/Services';
import Parcours from './Components/03-Parcours/A-Parcours';
import Philo from './Components/04-Philo/Philo';
import Portfolio from './Components/05-Portfolio/A-Portfolio';
import Contact from './Components/06-Contact/Contact';

function App() {
  return (
    <HelmetProvider>
      <Router>
        <Navbar />
        <div className="content">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/services" element={<Services />} />
            <Route path="/parcours" element={<Parcours />} />
            <Route path="/philosophie" element={<Philo />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/services/details" element={<DetailedServices />} />
            <Route path="/services/:serviceId" element={<SpecificServicePage />} />
            <Route path="/parcours/details" element={<DetailedParcours />} />
            <Route path="/projects/:projectId" element={<ProjectDetailPage />} />
            <Route path="/gallery" element={<AllProjectsPage />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </HelmetProvider>
  );
}

export default App;