import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import PortfolioData from '../../Data/PortfolioData';
import ProjectCard from '../05-Portfolio/ProjectCard';
import './Styles/B-ProjectsPage.scss';
import './Styles/B-ProjectsPageMobile.scss';
import './Styles/B-ProjectsPageTablette.scss';

const ProjectsPage = () => {
  const [activeTag, setActiveTag] = useState('Tous les projets');
  const [filteredProjects, setFilteredProjects] = useState(PortfolioData);

  useEffect(() => {
    const filtered = activeTag === 'Tous les projets' ? PortfolioData : PortfolioData.filter(project => project.tag === activeTag);
    setFilteredProjects(filtered);
  }, [activeTag]);

  const handleTagChange = (event) => {
    setActiveTag(event.target.value);
  };

  const uniqueTags = ['Tous les projets', ...new Set(PortfolioData.map(project => project.tag))];

  const metaDescription = activeTag === 'Tous les projets' 
    ? 'Découvrez tous nos projets réalisés par PAÏTAWEB, illustrant notre expertise en développement web et design.' 
    : `Découvrez nos projets dans la catégorie ${activeTag} réalisés par PAÏTAWEB, illustrant notre expertise en développement web et design.`;

  return (
    <div className='all-projects'>
      <Helmet defer={false}>
        <title>{`Projets - ${activeTag} - PAÏTAWEB`}</title>
        <meta name="description" content={metaDescription} />
        <meta name="keywords" content={`PAÏTAWEB, projets, ${activeTag}`} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.paitaweb.fr/projects" />
      </Helmet>
      <div className='header-all-projects'>
        <h2>Tous <br /> nos projets</h2>
        <img src="/img/logo.png" alt="Logo PAÏTAWEB" className="logoProjets" loading="lazy" />
      </div>
      <div className='menu-all-projects'>
        <div className='desktop-menu'>
          {uniqueTags.map(tag => (
            <button 
              key={tag} 
              onClick={() => setActiveTag(tag)} 
              className={activeTag === tag ? 'active' : ''}
            > 
              {tag}
            </button>
          ))}
        </div>
        <div className='mobile-menu'>
          <select onChange={handleTagChange} value={activeTag}>
            {uniqueTags.map(tag => (
              <option key={tag} value={tag}>
                {tag}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className='projects-container'>
        {filteredProjects.map((project) => (
          <ProjectCard key={project.id} card={project} />
        ))}
      </div>
    </div>
  );
};

export default ProjectsPage;