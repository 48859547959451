import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faCaretDown, faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import './Styles/NavBar.scss';



const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isServicesSubmenuOpen, setIsServicesSubmenuOpen] = useState(false);
  const [isPortfolioSubmenuOpen, setIsPortfolioSubmenuOpen] = useState(false);

  const isHomePage = location.pathname === '/';

  const goToTopOrHome = () => {
    if (isHomePage) {
      window.scrollTo(0, 0);
    } else {
      navigate('/');
    }
  };

  const handleNavigation = (target) => {
    if (isHomePage) {
      document.querySelector(target).scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      navigate('/');
      setTimeout(() => {
        document.querySelector(target).scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 500);
    }
  };

  const handlePageNavigation = (path) => {
    navigate(path);
    window.scrollTo(0, 0);
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const toggleContactModal = () => {
    if (isModalOpen) {
      setIsModalOpen(false);
    }
    setIsContactModalOpen(!isContactModalOpen);
  };

  const toggleServicesSubmenu = () => {
    setIsServicesSubmenuOpen(!isServicesSubmenuOpen);
  };

  const togglePortfolioSubmenu = () => {
    setIsPortfolioSubmenuOpen(!isPortfolioSubmenuOpen);
  };

  // Gère la fermeture des modales en cliquant en dehors d'elles
  useEffect(() => {
    const close = (e) => {
      if (e.target.className === 'nav-modal') {
        setIsModalOpen(false);
      }
      if (e.target.className === 'contact-modal') {
        setIsContactModalOpen(false);
      }
    };

    if (isModalOpen || isContactModalOpen) {
      window.addEventListener('click', close);
    }

    return () => window.removeEventListener('click', close);
  }, [isModalOpen, isContactModalOpen]);

  return (
    <nav className="navbar">
      <div className="logoNav" onClick={() => handleNavigation('#home')} aria-label="Aller à l'accueil">
        <img src="/img/logo.png" alt="Logo PAÏTAWEB" className="logoImageNavigation" />
      </div>
      <div className="mobile-menu-icon" onClick={toggleModal} aria-label="Ouvrir le menu mobile">
        <FontAwesomeIcon icon={faBars} /> {/* Icône FontAwesome pour le menu */}
      </div>
      <div className="nav-links">
        <div className="nav-link services-link" onMouseEnter={() => setIsServicesSubmenuOpen(true)} onMouseLeave={() => setIsServicesSubmenuOpen(false)}>
          <button onClick={() => handleNavigation('#services')} aria-label="Services">Services <FontAwesomeIcon icon={faCaretDown} /></button>
          {isServicesSubmenuOpen && (
            <div className="services-submenu">
              <button className="nav-link" onClick={() => handlePageNavigation('/services/details')} aria-label="Nos services en détails">Nos services en détails</button>
            </div>
          )}
        </div>
        <button className="nav-link" onClick={() => handleNavigation('#philosophie')} aria-label="Philo">Philo</button>
        <button className="nav-link" onClick={() => handleNavigation('#parcours')} aria-label="Parcours">Parcours</button>
        <div className="nav-link portfolio-link" onMouseEnter={() => setIsPortfolioSubmenuOpen(true)} onMouseLeave={() => setIsPortfolioSubmenuOpen(false)}>
          <button onClick={() => handleNavigation('#portfolio')} aria-label="Portfolio">Portfolio <FontAwesomeIcon icon={faCaretDown} /></button>
          {isPortfolioSubmenuOpen && (
            <div className="portfolio-submenu">
              <button className="nav-link" onClick={() => handlePageNavigation('/gallery')} aria-label="Nos projets en détails">Nos projets en détails</button>
            </div>
          )}
        </div>
      </div>

      <div className="nav-cta">
        <button className="nav-link" onClick={toggleContactModal} aria-label="Contactez-nous">Contactez-nous</button>
      </div>
      {isModalOpen && (
        <div className="nav-modal">
          <div className="modal-content">
            <img src="/img/logo.png" alt="Logo PAÏTAWEB" className="logoImageNavMobile" onClick={() => handleNavigation('#home')} aria-label="Aller à l'accueil" />
            <button className="nav-link" onClick={() => { handleNavigation('#home'); toggleModal(); }} aria-label="Accueil">Accueil</button>
            <div className="nav-link services-link" onMouseEnter={() => setIsServicesSubmenuOpen(true)} onMouseLeave={() => setIsServicesSubmenuOpen(false)}>
              <button onClick={() => handleNavigation('#services')} aria-label="Services">Services <FontAwesomeIcon icon={faCaretDown} /></button>
              {isServicesSubmenuOpen && (
                <div className="services-submenu">
                  <button className="nav-link" onClick={() => { handlePageNavigation('/services/details'); toggleModal(); }} aria-label="Nos services en détails">Nos services en détails</button>
                </div>
              )}
            </div>
            <button className="nav-link" onClick={() => { handleNavigation('#parcours'); toggleModal(); }} aria-label="Parcours">Parcours</button>
            <button className="nav-link" onClick={() => { handleNavigation('#philosophie'); toggleModal(); }} aria-label="Philo">Philo</button>
            <div className="nav-link portfolio-link" onMouseEnter={() => setIsPortfolioSubmenuOpen(true)} onMouseLeave={() => setIsPortfolioSubmenuOpen(false)}>
              <button onClick={() => handleNavigation('#portfolio')} aria-label="Portfolio">Portfolio <FontAwesomeIcon icon={faCaretDown} /></button>
              {isPortfolioSubmenuOpen && (
                <div className="portfolio-submenu">
                  <button className="nav-link" onClick={() => { handlePageNavigation('/gallery'); toggleModal(); }} aria-label="Nos projets en détails">Nos projets en détails</button>
                </div>
              )}
            </div>
            <button className="nav-link" onClick={() => { handleNavigation('#contact'); toggleModal(); }} aria-label="Contact">Contact</button>
            <button className="nav-link-button" onClick={toggleContactModal} aria-label="Démarrez votre projet">Démarrez votre projet</button>
            <button onClick={toggleModal} className="close-modal" aria-label="Fermer le menu">
              <FontAwesomeIcon icon={faTimes} /> {/* Icône FontAwesome pour fermer */}
            </button>
          </div>
        </div>
      )}
      {isContactModalOpen && (
        <div className="contact-modal">
          <div className="contact-modal-content">
            <h2>Contactez-nous</h2>
            <a href="mailto:mateo@paita.net" className="contact-option" aria-label="Envoyer un mail">
              <FontAwesomeIcon icon={faEnvelope} /> Envoyer un mail
            </a>
            <a href="tel:0608617000" className="contact-option" aria-label="Téléphoner">
              <FontAwesomeIcon icon={faPhone} /> Téléphoner
            </a>
            <button onClick={toggleContactModal} className="close-contact-modal" aria-label="Fermer la modale">
              <FontAwesomeIcon icon={faTimes} /> {/* Icône FontAwesome pour fermer */}
            </button>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;