import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './LandingPage.scss';
import HomePage from '../../01-HomePage/HomePage';
import Services from '../../02-Services/Services';
import Parcours from '../../03-Parcours/A-Parcours';
import Philo from '../../04-Philo/Philo';
import Portfolio from '../../05-Portfolio/A-Portfolio';
import Contact from '../../06-Contact/Contact';
import Footer from '../Footer/Footer';

const sectionMeta = {
  home: {
    title: 'PAÏTAWEB - Accueil',
    description: 'Découvrez PAÏTAWEB, votre partenaire en solutions numériques. Explorez nos services, notre parcours, notre philosophie, notre portfolio et contactez-nous pour vos projets.',
    keywords: 'PAÏTAWEB, landing page, services, parcours, philosophie, portfolio, contact',
    canonical: 'https://www.paitaweb.fr/',
  },
  services: {
    title: 'Nos Services - PAÏTAWEB',
    description: 'Découvrez nos services sur mesure en développement web, UX/UI, applications mobiles et SEO. Solutions adaptées à tout budget.',
    keywords: 'PAÏTAWEB, services, développement web, UX/UI, applications mobiles, SEO, Gap, Vienne',
    canonical: 'https://www.paitaweb.fr/services',
  },
  philosophie: {
    title: 'Notre Philosophie - PAÏTAWEB',
    description: 'Découvrez notre vision chez PAÏTAWEB. Un futur numérique accessible, éthique et impactant.',
    keywords: 'PAÏTAWEB, philosophie, vision, numérique, éthique, impact',
    canonical: 'https://www.paitaweb.fr/philo',
  },
  parcours: {
    title: 'Les étapes - PAÏTAWEB',
    description: 'Découvrez les étapes de notre processus chez PAÏTAWEB. De la conceptualisation au lancement, nous vous accompagnons à chaque étape.',
    keywords: 'PAÏTAWEB, parcours, étapes, développement web, UX/UI, stratégie, lancement',
    canonical: 'https://www.paitaweb.fr/parcours',
  },
  portfolio: {
    title: 'Notre Portfolio - PAÏTAWEB',
    description: 'Découvrez notre portfolio chez PAÏTAWEB, illustrant notre passion pour la conception web et notre capacité à transformer des idées en réalités numériques.',
    keywords: 'PAÏTAWEB, portfolio, projets, conception web, design web, réalisations',
    canonical: 'https://www.paitaweb.fr/portfolio',
  },
  contact: {
    title: 'Contactez-nous - PAÏTAWEB',
    description: 'Contactez PAÏTAWEB pour discuter de vos projets numériques. Connectons nos idées pour créer le futur ensemble.',
    keywords: 'PAÏTAWEB, contact, projets numériques, développement web, design web, services',
    canonical: 'https://www.paitaweb.fr/contact',
  },
};

function LandingPage() {
  const [activeSection, setActiveSection] = useState('home');

  const handleScroll = () => {
    const sections = document.querySelectorAll('section');
    let currentSection = 'home';

    sections.forEach((section) => {
      const rect = section.getBoundingClientRect();
      if (rect.top >= 0 && rect.top < window.innerHeight / 2) {
        currentSection = section.getAttribute('id');
      }
    });

    if (currentSection && currentSection !== activeSection) {
      setActiveSection(currentSection);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Mettre à jour l'état immédiatement après le montage

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [activeSection]);

  const meta = sectionMeta[activeSection] || sectionMeta.home;

  return (
    <HelmetProvider>
      <div className="App">
        <Helmet>
          <title>{meta.title}</title>
          <meta name="description" content={meta.description} />
          <meta name="keywords" content={meta.keywords} />
          <meta name="robots" content="index, follow" />
          <link rel="canonical" href={meta.canonical} />
        </Helmet>
        <main className="landing-page">
          <section id="home"><HomePage /></section>
          <section id="services"><Services /></section>
          <section id="philosophie"><Philo /></section>
          <section id="parcours"><Parcours /></section>
          <section id="portfolio"><Portfolio /></section>
          <section id="contact"><Contact /></section>
        </main>
      </div>
    </HelmetProvider>
  );
}

export default LandingPage;