import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { servicesData } from '../../Data/ServicesData';
import './Style/SpecificServicePage.scss';
import './Style/SpecificServicePageTablette.scss';
import './Style/SpecificServicePageMobile.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SpecificServicePage = () => {
  let { serviceId } = useParams();
  const service = servicesData.find(service => service.path === serviceId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!service) {
    return <div>Service non trouvé</div>;
  }

  return (
    <section className='LayoutSpecific'>
      <Helmet>
        <title>{service.title} - PAÏTAWEB</title>
        <meta name="description" content={service.descriptionDesktop} />
        <meta name="keywords" content={service.tags.join(', ')} />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href={`https://www.paitaweb.com/services/${serviceId}`} />
      </Helmet>
      <div className='SpecificPage'>
        <div className='BlocSpecific1'> 
          <div className='blocTextIntroSpecific'>
          <img src="/img/logo.png" alt="Bulle avec le texte blah blah blah" className="logoPaitaServices"/>

          <h2>{service.title}</h2>
          <div className="service-icons">
            {service.icons.map((icon, index) => (
              <FontAwesomeIcon key={service.id + '-' + index} icon={icon} className="custom-icon" />
            ))}
          </div>
          <p>{service.descriptionDesktop}</p>

          </div>


          {/* Affichage des phrases clés */}
          {service.tags && (
            <ul className='ListeCles'>
              {service.tags.map((phrase, index) => (
                <li key={index} style={{ backgroundColor: service.color }}>{phrase}</li>
              ))}
            </ul>
          )}

          <div className="service-icons-desktop">
            {service.icons.map((icon, index) => (
              <FontAwesomeIcon key={service.id + '-' + index} icon={icon} className="custom-icon" />
            ))}
          </div>

          <div className='PhotosSpecificMobile'>
            <img src={service.image} alt={`Illustration de ${service.title}`} loading="lazy" />
          </div>
        </div>
        <div className='BlocSpecific2'>
          {/* Affichage des photos */}
          {service.image && (
            <div className='PhotosSpecific'>
              <img src={service.image} alt={`Illustration de ${service.title}`} loading="lazy" />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default SpecificServicePage;