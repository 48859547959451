import { useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import './Styles/HomePage.scss';
import './Styles/HomePageTablette.scss';
import './Styles/HomePageMobile.scss';
import TexteAnimated from './Components/TextAnimated/TextAnimated';

function HomePage() {
  const sectionRef = useRef(null);
  const mainsRef = useRef(null);
  const [pulseProps, setPulse] = useSpring(() => ({
    transform: 'scale(1)',
  }));

  const handleLogoClick = () => {
    setPulse.start({ transform: 'scale(1.1)' });
    setTimeout(() => {
      setPulse.start({ transform: 'scale(1)' });
    }, 200); // Durée de la pulsation
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const scale = 1 + scrollY / 1000;
      if (mainsRef.current && !document.body.classList.contains('disable-zoom')) {
        mainsRef.current.style.transform = `scale(${scale})`;
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const logoProps = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 600 });
  const textProps = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 600 });
  const socialProps = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 600 });
  const handsProps = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 200 });
  const locationProps = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 600 });
  const timeProps = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 600 });

  return (
    <section className="homePage" id="home" ref={sectionRef}>
      <animated.div style={handsProps} className="fond-mains">
        <img ref={mainsRef} src="/img/mains.png" alt="illustration de mains" className="mains" />
      </animated.div>
      <animated.div style={{ ...logoProps }} className="logo">
        <animated.img src="/img/logo.png" alt="Logo PAÏTAWEB" className="logoCentral" style={pulseProps} onClick={handleLogoClick} />
      </animated.div>
      <animated.div style={textProps} className="texteHome">
        <TexteAnimated />
      </animated.div>
      <animated.div style={locationProps} className="textLoca">
        <h3><a href="https://www.google.com/maps/place/Lyon,+France" target="_blank" rel="noopener noreferrer"><strong>Lyon - Gap</strong> et partout dans le monde.</a></h3>
      </animated.div>
      <animated.div style={timeProps} className="horaire">
      </animated.div>
      <animated.div style={socialProps} className="socialHome">
        <img src="/img/insta.webp" alt="Icone Instagram" className="socialIcons" />
        <img src="/img/behance.png" alt="Icone Behance" className="socialIcons" />
        <img src="/img/facebook.png" alt="Icone Facebook" className="socialIcons" />
      </animated.div>
    </section>
  );
}

export default HomePage;