import React from "react";
import { useNavigate } from "react-router-dom";
import { servicesData } from '../../Data/ServicesData.js'; // Importation des données de services
import './Style/Services.scss'; // Importation du style principal
import './Style/ServicesMobile.scss'; // Importation du style responsive
import './Style/ServicesTablette.scss'; // Importation du style responsive
import ServicesCarrousel from './Components/ServicesCarrousel/ServicesCarrousel.js'; // Importation du composant de carrousel

const Services = () => {
  const navigate = useNavigate(); // Hook pour la navigation

  // Fonction de gestion du clic qui redirige vers la page des services
  const handleClick = () => {
    navigate("/services/details");
    window.scrollTo(0, 0);
  };

  return (
    <section className="servicesSection">
      <div className="sectionHeader">
      <img src="/img/services.gif" alt="Bulle avec le texte blah blah blah" className="iconeSectionHeader"/>

        <h2>NOS SERVICES</h2>
        <h3>Des possibilités infinies pour chaque défi</h3>
        <p className="blocInfoSection desktop">
          Nos Services chez <strong>PAÏTAWEB</strong> offrent des <strong>solutions sur mesure</strong> pour chaque défi numérique. Depuis <strong>Gap</strong> et <strong>Vienne</strong>, nos experts allient <strong>conception UX/UI</strong>, <strong>développement d'applications mobiles</strong> et <strong>optimisation SEO</strong> pour transformer vos idées en réalités numériques performantes. Nos solutions <strong>adaptatives</strong> conviennent à <strong>tout budget</strong>, enrichissant votre <strong>présence en ligne</strong> avec <strong>innovation</strong> et <strong>efficacité</strong>. Explorez les possibilités infinies que nous pouvons apporter à votre projet.
        </p>
        <p className="blocInfoSection mobile">
          Nos Services chez <strong>PAÏTAWEB</strong> offrent des solutions sur mesure pour chaque défi numérique. Depuis <strong>Gap</strong> et <strong>Vienne</strong>, nos experts allient <strong>conception UX/UI</strong>, <strong>développement mobile</strong> et <strong>optimisation SEO</strong>. Nos solutions adaptatives conviennent à tout budget, enrichissant votre présence en ligne avec innovation et efficacité.
        </p>
      </div>

      <div className="flexContainer">
        <div className="serviceBlock">
          <ServicesCarrousel />
        </div>
      </div>
      <button className="button-details" onClick={handleClick}>
        Nos services en détails
      </button>
    </section> 
  );
};

export default Services;