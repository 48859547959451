import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import './Styles/B-ProjectsPage.scss';
import './Styles/ProjectCard.scss';

const ProjectCard = ({ card }) => {
  let navigate = useNavigate();
  const [isHovered, setIsHovered] = useState(false);

  const goToProjectPage = (path) => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate(`/projects/${path}`);
  };

  const props = useSpring({
    transform: isHovered ? 'scale(1.05)' : 'scale(1)',
    boxShadow: isHovered
      ? '0px 10px 30px rgba(0, 0, 0, 0.2)'
      : '0px 0px 0px rgba(0, 0, 0, 0)',
  });

  return (
    <animated.div
      className="project-card"
      style={{ ...props, backdropFilter: 'blur(15px)' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => goToProjectPage(card.path)}
    >
      <div className='card-content-project'>
        <img src={card.image} alt={card.title} className='project-card-image' />
        <p>{card.description}</p>
        <button onClick={() => goToProjectPage(card.path)}>Ouvrir le projet</button>
      </div>
    </animated.div>
  );
};

export default ProjectCard;