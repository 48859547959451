const stepsData = [
    {
      number: '01',
      title: 'Conceptualisation & Stratégie',
      description: (
        <span>
          La première étape du processus chez PAÏTAWEB consiste en une phase de <strong>conceptualisation et stratégie</strong>. Cette étape cruciale permet de poser les bases solides de votre projet. Nous travaillons étroitement avec vous pour définir vos objectifs, comprendre votre marché cible et élaborer une stratégie digitale sur mesure. C'est le moment où vos idées commencent à prendre forme, guidées par nos experts pour maximiser votre impact en ligne.
        </span>
      ),      keySteps: ['Sessions de brainstorming', 'Analyse de marché', 'Définition des objectifs', 'Un quatrième'],
      tags: ['🧠 Définir clairement les objectifs du projet.', '🔍 Identifier le public cible et les besoins.', '📈 Définition des objectifs', '👷‍♂️ Planifier l\'architecture du site et la navigation.'],
      logo: process.env.PUBLIC_URL + '/img/3.svg', // Replace with the actual path to your logo
    },
    {
      number: '02',
      title: 'Conception Visuelle et UX/UI ',
            keySteps: ['Sessions de brainstorming', 'Analyse de marché', 'Définition des objectifs', 'Un quatrième'],
            description: (
              <span>
                Dans la deuxième étape, notre focus se porte sur la <strong>conception visuelle et l'expérience utilisateur (UX/UI)</strong>. Nos designers utilisent des principes de design innovants pour créer des interfaces esthétiquement plaisantes et fonctionnellement intuitives. Cette phase aboutit à des prototypes interactifs, vous permettant de visualiser la solution finale avant même le développement, assurant ainsi que le produit fini sera non seulement beau mais aussi extrêmement utilisateur-friendly.
              </span>
            ),
            
      logo: process.env.PUBLIC_URL + '/img/6.svg', // Replace with the actual path to your logo
      tags : ['✨ Créer une identité visuelle captivante.', '🧩 Concevoir une expérience utilisateur intuitive.', '👆 Élaborer des prototypes interactifs.', '🖌️ Développer une charte graphique personnalisée.'],
    },
  
    {
      number: '03',
      title: 'Développement Agile',
      description: (
        <span>
          Le <strong>développement agile</strong> constitue la troisième étape de notre processus. Chez PAÏTAWEB, nous adoptons une approche itérative qui permet de rester flexible et réactif face aux changements. Cette méthode favorise la collaboration étroite entre nos équipes et vous, le client, permettant des ajustements en temps réel et une optimisation continue du produit en cours de création, garantissant ainsi que le projet évolue selon vos besoins spécifiques.
        </span>
      ),
      keySteps: ['Sessions de brainstorming', 'Analyse de marché', 'Définition des objectifs', 'Un quatrième'],
      logo: process.env.PUBLIC_URL + '/img/5.svg', // Replace with the actual path to your logo
      tags : ['🛠️ Programmer des fonctionnalités robustes.', '🤖 Utiliser les dernières technologies web.', '🚨 Intégrer des fonctionnalités de sécurité.', '📝 Suivre une approche de développement itérative.'],
    },
  
    {
      number: '04',
      title: 'Tests Rigoureux et Lancement ',
      description: (
        <span>
          La dernière étape avant le lancement de votre projet est la réalisation de <strong>tests rigoureux</strong>. Nos experts s'assurent que le produit final est non seulement conforme à vos attentes mais aussi exempt de tout défaut. Cette phase de tests est cruciale pour garantir une expérience utilisateur sans faille. Une fois les tests validés, nous procédons au <strong>lancement</strong> du projet, marquant le début de votre nouvelle présence en ligne dynamique et optimisée.
        </span>
      ),
            keySteps: ['Sessions de brainstorming', 'Analyse de marché', 'Définition des objectifs', 'Un quatrième'],
      logo: process.env.PUBLIC_URL + '/img/4.svg', // Replace with the actual path to your logo
      tags : ['🔋 Effectuer des tests de performance et de sécurité.', '🌐 Valider la compatibilité avec les navigateurs.', '📱 Assurer une expérience sans faille sur tous les appareils.', '🔎 Lancer le site avec succès et surveiller le flux de trafic.'],
    },
  ];

  export default stepsData;