import React from 'react';
import Slider from 'react-slick';
import './Style/ServicesCarrousel.scss';
import './Style/ServicesCarrouselTablette.scss';
import './Style/ServicesCarrouselMobile.scss';
import { servicesData } from '../../../../Data/ServicesData'; // Assurez-vous que le chemin d'accès est correct
import ServiceCard from '../ServicesCard/ServicesCard'; // Assurez-vous que le chemin d'accès est correct

const ServicesCarrousel = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
  };

  // Filtrer les services pour n'afficher que ceux dont l'ID est compris entre 1 et 6
  const filteredServices = servicesData.filter(service => service.id >= 1 && service.id <= 6);

  return (
    <div className="services-carousel">
      <Slider {...settings}>
        {filteredServices.map((service, index) => (
          <div key={index} className="blocCardServices">
            <ServiceCard service={service} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ServicesCarrousel;