import React from 'react';
import { Helmet } from 'react-helmet-async';
import { servicesData } from '../../Data/ServicesData';
import DetailedServicesComponent from './Component/DetailedServicesComponent';
import './Style/DetailedServices.scss';
import './Style/DetailedServicesMobile.scss';
import './Style/DetailedServicesTablette.scss';
import { useMediaQuery } from 'react-responsive';

const DetailedServices = () => {
  const [activeTag, setActiveTag] = React.useState('all');
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  if (!Array.isArray(servicesData)) {
    console.error('servicesData doit être un tableau');
    return null;
  }

  const filteredServices = activeTag === 'all'
    ? servicesData
    : servicesData.filter(service => service.tag === activeTag);

  const handleTagClick = (tag) => {
    setActiveTag(tag);
  };

  return (
    <section className="detailed-services">
      <Helmet defer={false}>
        <title>Nos Services en Détail - PAÏTAWEB</title>
        <meta name="description" content="Découvrez en détail les services offerts par PAÏTAWEB, incluant le développement web, la conception UX/UI, les applications mobiles et l'optimisation SEO." />
        <meta name="keywords" content="PAÏTAWEB, services détaillés, développement web, UX/UI, applications mobiles, SEO" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.paitaweb.fr/services/details" />
      </Helmet>
      <div className='headerDetailedServices'>
        <img src="/img/logo.png" alt="Globe" className="logoServices" loading="lazy" />
        <h2 className='srvc-details'>NOS SERVICES <br/>EN DÉTAILS</h2>
      </div>
      <div className="service-tags">
        {isMobile ? (
          <select
            value={activeTag}
            onChange={(e) => handleTagClick(e.target.value)}
            className="tag-select"
          >
            <option value="all">Tous les services</option>
            {servicesData.map(service => service.tag)
              .filter((value, index, self) => self.indexOf(value) === index)
              .map(tag => (
                <option key={tag} value={tag}>
                  {tag}
                </option>
              ))}
          </select>
        ) : (
          <>
            <button
              className={`tag-button ${activeTag === 'all' ? 'active' : ''}`}
              onClick={() => handleTagClick('all')}
            >
              Tous les services
            </button>
            {servicesData.map(service => service.tag)
              .filter((value, index, self) => self.indexOf(value) === index)
              .map((tag) => (
                <button
                  key={tag}
                  className={`tag-button ${tag} ${activeTag === tag ? 'active' : ''}`}
                  onClick={() => handleTagClick(tag)}
                >
                  {tag}
                </button>
              ))}
          </>
        )}
      </div>
      <div className="service-section">
        {filteredServices.map(service => (
          <DetailedServicesComponent key={service.id} {...service} className={service.tag} />
        ))}
      </div>
    </section>
  );
}; 

export default DetailedServices;