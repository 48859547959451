import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Style/ServicesCard.scss';
import './Style/ServicesCardTablette.scss';
import './Style/ServicesCardMobile.scss';

const useDeviceType = () => {
  const [deviceType, setDeviceType] = useState('desktop');

  useEffect(() => {
    const updateDeviceType = () => {
      const width = window.innerWidth;
      if (width < 1024) {
        setDeviceType('mobile'); // Mobile and tablet use the same text
      } else {
        setDeviceType('desktop');
      }
    };

    window.addEventListener('resize', updateDeviceType);
    updateDeviceType(); // Vérification initiale

    return () => window.removeEventListener('resize', updateDeviceType);
  }, []);

  return deviceType;
};

const ServiceCard = ({ service }) => {
  const deviceType = useDeviceType();
  const description = deviceType === 'mobile' ? service.descriptionMobile : service.descriptionDesktop;
  const navigate = useNavigate();

  // Définition du style en fonction du dégradé linéaire
  const cardStyle = {
    backgroundImage: `linear-gradient(to right, ${service.gradientColors.join(', ')})`,
  };

  const [isDragging, setIsDragging] = useState(false);
  const dragStartX = useRef(0);
  const dragStartY = useRef(0);

  const handleMouseDown = (e) => {
    setIsDragging(false);
    dragStartX.current = e.clientX;
    dragStartY.current = e.clientY;
  };

  const handleMouseMove = (e) => {
    if (
      Math.abs(e.clientX - dragStartX.current) > 5 ||
      Math.abs(e.clientY - dragStartY.current) > 5
    ) {
      setIsDragging(true);
    }
  };

  const handleMouseUp = () => {
    if (!isDragging) {
      handleCardClick();
    }
  };

  const handleCardClick = () => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    navigate(`/services/${service.path}`);
  };

  return (
    <div
      className="service-card"
      style={cardStyle}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
    >
      <div className="service-image">
        <img src={service.image} alt={service.title} />
      </div>
      <div className="service-content">
        <div className="service-header-card">
          <h4>{service.title}</h4>
          <h5>{service.subtitle}</h5>
          <p>{description}</p>
        </div>
        <ul className="services-tags">
          {service.tags.map((tag, index) => (
            tag && <li key={index}>{tag}</li>
          ))}
        </ul>        
        <div className="service-icons">
          {service.icons.map((icon, index) => (
            <FontAwesomeIcon key={service.id + '-' + index} icon={icon} className="custom-icon" />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;